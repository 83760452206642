import { useEffect, useState } from "react";
import { userApi } from "../../../api/user.api";
import { kFormatter } from "../../../utils/util";
import global from "../../../redux/global";
import { useTranslation } from "react-i18next";
import "./style.css";

const RetailerUniqueUserDesktop = () => {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    if (!global.IS_DROB_A) {
      userApi.countNewUsers().then((data) => {
        setData(data);

        if (data) {
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear();

          let lastMonth = currentMonth > 1 ? currentMonth - 1 : 12;
          let yearOfLastMonth =
            lastMonth === 12 ? currentYear - 1 : currentYear;

          setChartData({
            labels: [
              `${lastMonth}/${yearOfLastMonth}`,
              `${currentMonth}/${currentYear}`,
            ],
            values: [data.lastMonth, data.thisMonth],
          });
        }
      });
    }
  }, []);

  return (
    <>
      <div className="new-users-text flex flex-col justify-center p-2  gap-[12px] overflow-hidden">
        <div className="flex justify-between items-center self-stretch">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M21.6484 19.8751C20.2206 17.4067 18.0203 15.6367 15.4525 14.7976C16.7226 14.0415 17.7094 12.8893 18.2614 11.518C18.8134 10.1468 18.8999 8.63224 18.5078 7.20701C18.1157 5.78178 17.2666 4.52467 16.0909 3.62873C14.9151 2.73279 13.4778 2.24756 11.9996 2.24756C10.5215 2.24756 9.08414 2.73279 7.90842 3.62873C6.73269 4.52467 5.88358 5.78178 5.49146 7.20701C5.09935 8.63224 5.18592 10.1468 5.73788 11.518C6.28984 12.8893 7.27668 14.0415 8.54683 14.7976C5.97902 15.6357 3.77871 17.4057 2.35089 19.8751C2.29853 19.9605 2.2638 20.0555 2.24875 20.1545C2.2337 20.2535 2.23863 20.3545 2.26326 20.4516C2.28789 20.5487 2.33171 20.6399 2.39214 20.7197C2.45257 20.7996 2.52838 20.8666 2.6151 20.9167C2.70183 20.9667 2.79771 20.999 2.89709 21.0114C2.99647 21.0238 3.09733 21.0163 3.19373 20.9891C3.29012 20.9619 3.3801 20.9157 3.45835 20.8532C3.5366 20.7907 3.60154 20.7131 3.64933 20.6251C5.41558 17.5726 8.53746 15.7501 11.9996 15.7501C15.4618 15.7501 18.5837 17.5726 20.35 20.6251C20.3977 20.7131 20.4627 20.7907 20.5409 20.8532C20.6192 20.9157 20.7092 20.9619 20.8056 20.9891C20.902 21.0163 21.0028 21.0238 21.1022 21.0114C21.2016 20.999 21.2975 20.9667 21.3842 20.9167C21.4709 20.8666 21.5467 20.7996 21.6072 20.7197C21.6676 20.6399 21.7114 20.5487 21.736 20.4516C21.7607 20.3545 21.7656 20.2535 21.7505 20.1545C21.7355 20.0555 21.7008 19.9605 21.6484 19.8751ZM6.74964 9.00011C6.74964 7.96176 7.05755 6.94672 7.63443 6.08337C8.21131 5.22001 9.03124 4.5471 9.99056 4.14974C10.9499 3.75238 12.0055 3.64841 13.0239 3.85099C14.0423 4.05356 14.9777 4.55357 15.712 5.2878C16.4462 6.02202 16.9462 6.95748 17.1488 7.97589C17.3513 8.99429 17.2474 10.0499 16.85 11.0092C16.4527 11.9685 15.7797 12.7884 14.9164 13.3653C14.053 13.9422 13.038 14.2501 11.9996 14.2501C10.6077 14.2486 9.27322 13.695 8.28898 12.7108C7.30473 11.7265 6.75113 10.392 6.74964 9.00011Z" fill="black"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M9.84375 9C9.84375 9.16688 9.79426 9.33001 9.70155 9.46876C9.60884 9.60752 9.47706 9.71566 9.32289 9.77952C9.16871 9.84339 8.99906 9.8601 8.83539 9.82754C8.67172 9.79498 8.52138 9.71462 8.40338 9.59662C8.28538 9.47862 8.20502 9.32828 8.17246 9.16461C8.13991 9.00094 8.15662 8.83129 8.22048 8.67711C8.28434 8.52294 8.39248 8.39116 8.53124 8.29845C8.66999 8.20574 8.83312 8.15625 9 8.15625C9.22378 8.15625 9.43839 8.24515 9.59662 8.40338C9.75486 8.56161 9.84375 8.77622 9.84375 9ZM9 5.0625C9.16688 5.0625 9.33001 5.01302 9.46876 4.9203C9.60752 4.82759 9.71566 4.69581 9.77952 4.54164C9.84338 4.38746 9.86009 4.21781 9.82754 4.05414C9.79498 3.89047 9.71462 3.74013 9.59662 3.62213C9.47862 3.50413 9.32828 3.42377 9.16461 3.39121C9.00094 3.35866 8.83129 3.37537 8.67711 3.43923C8.52294 3.50309 8.39116 3.61123 8.29845 3.74999C8.20574 3.88874 8.15625 4.05187 8.15625 4.21875C8.15625 4.44253 8.24515 4.65714 8.40338 4.81537C8.56161 4.97361 8.77622 5.0625 9 5.0625ZM9 12.9375C8.83312 12.9375 8.66999 12.987 8.53124 13.0797C8.39248 13.1724 8.28434 13.3042 8.22048 13.4584C8.15662 13.6125 8.13991 13.7822 8.17246 13.9459C8.20502 14.1095 8.28538 14.2599 8.40338 14.3779C8.52138 14.4959 8.67172 14.5762 8.83539 14.6088C8.99906 14.6413 9.16871 14.6246 9.32289 14.5608C9.47706 14.4969 9.60884 14.3888 9.70155 14.25C9.79426 14.1113 9.84375 13.9481 9.84375 13.7813C9.84375 13.5575 9.75486 13.3429 9.59662 13.1846C9.43839 13.0264 9.22378 12.9375 9 12.9375Z" fill="black"/>
</svg>
        </div>
        
          <h1 className="text-[#141414] flex items-start font-inter text-sm font-medium ">Unique User</h1>

          <div className="flex gap-4 ">
           <p className="text-[#141414] font-inter text-lg font-bold ">1498</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M29.7451 6.33333V11.6667C29.7451 12.403 29.1481 13 28.4118 13C27.6754 13 27.0784 12.403 27.0784 11.6667V9.69333L18.7451 19.2133C18.4923 19.501 18.1281 19.6662 17.7451 19.6667C17.3749 19.6761 17.0175 19.5312 16.7584 19.2667L13.7718 16.2533L5.37176 25.88C5.06425 26.2618 4.57204 26.444 4.09004 26.3546C3.60804 26.2651 3.21402 25.9184 3.064 25.4517C2.91398 24.9849 3.03217 24.4736 3.37176 24.12L12.7051 13.4533C12.9478 13.1752 13.295 13.0102 13.664 12.9976C14.0329 12.9849 14.3906 13.1258 14.6518 13.3867L17.7451 16.3733L25.3051 7.66667H23.0784C22.342 7.66667 21.7451 7.06971 21.7451 6.33333C21.7451 5.59695 22.342 5 23.0784 5H28.4118C29.1481 5 29.7451 5.59695 29.7451 6.33333Z"
                fill="#141414"
              />
            </svg>
          </div>
       
      </div>
    </>
  );
};
export default RetailerUniqueUserDesktop;
