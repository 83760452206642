// import { Col, Row, Spin, notification } from "antd"
// import LOGO from "../../../assets/images/LOGO.svg";
// import CarouselLoginAndRegister from "../../../components/carouselLoginAndRegister/CarouselLoginAndRegister";
// import { useState } from "react";
// import authApi from "../../../api/auth.api";
// import _ from "lodash";
// import { useNavigate } from "react-router-dom";
// import routesConstant from "../../../routes/routesConstant";

// import BackGround1 from "../../../assets/images/login/bg-1.png"
// import BackGround2 from "../../../assets/images/login/bg-2.png"
// import BackGround3 from "../../../assets/images/login/bg-3.png"

// const ResetPassword = () => {
//   const navigate = useNavigate()
//   const [password1, setPassword1] = useState("")
//   const [password2, setPassword2] = useState("")
//   const [loading, setLoading] = useState(false)

//   const [index, setIndex] = useState(0)
//   const [items, setItems] = useState([
//     {
//         id: 1,
//         background: BackGround1
//     },
//     {
//         id: 2,
//         background: BackGround2
//     },
//     {
//         id: 3,
//         background: BackGround3
//     }
//   ])

//   const onSubmit = () => {
//     if (!password1 || !password2) {
//       notification.warning({
//         message: "One or more fields is invalid."
//       })
//       return
//     }
//     if (password1 !== password2) {
//       notification.warning({
//         message: "Password must be same."
//       })
//       return
//     }
//     const token = new URLSearchParams(window.location.search).get(
//       "token"
//     );
//     if(!token){
//       notification.warning({
//         message: "Can't find your token."
//       })
//       return
//     }
//     setLoading(true)
//     authApi.resetPassword(token, {password: password1}).then(rs => {
//       notification.success({
//         message: "Reset password successfully!"
//       })
//       setLoading(false)
//       navigate(routesConstant.login.path)
//     }).catch(err => {
//       notification.error({
//         message: _.get(err, ['response', 'data', 'message'], `Can't reset password!`)
//       })
//       setLoading(false)
//     })
//   }
//   return <>
//     <div className='px-[32px] md:px-[73px] login-wrapper min-h-screen flex justify-center items-center w-full relative'>
//       {
//         items.map((el, idx) => (
//           <div
//               className={`login-image-wrapper ${index % items.length === idx ? 'active' : ''}`}
//               key={el.id}
//               style={{
//                   backgroundImage: `url(${el.background})`
//               }}
//           >
//           </div>
//         ))
//       }
//       <Row className='login__container rounded-[10px] md:rounded-[20px] overflow-hidden w-full min-h-[90vh] relative z-[2]'>
//         <Col xxl={11} xl={11} lg={11} md={11} sm={24} xs={24} className='left-col flex flex-col gap-[24px] 2xl:gap-[50px]'>
//           <div className='flex justify-start w-full'>
//             <img src={LOGO} alt="" className='h-[44px]'/>
//           </div>
//             <div className='w-full flex items-center flex-auto'>
//                 <div className='login-form'>
//                     <div className='login-title'>Reset password</div>
//                     <div className='sub__login-title'>Enter your new password</div>
//                     <div className='form-input-container mt-[24px] 2xl:mt-[50px]'>
//                         <div className='form-title'>
//                           Password
//                         </div>
//                         <input
//                           type='password'
//                           className='input-text-form'
//                           placeholder='* * * * * * * *'
//                           value={password1}
//                           onChange={(e) => {setPassword1(e.target.value)}}
//                           autoComplete='off'
//                         />
//                     </div>
//                     <div className='form-input-container mt-[24px] 2xl:mt-[50px]'>
//                         <div className='form-title'>
//                           Repeat Password
//                         </div>
//                         <input
//                           type='password'
//                           className='input-text-form'
//                           placeholder='* * * * * * * *'
//                           value={password2}
//                           onChange={(e) => {setPassword2(e.target.value)}}
//                           autoComplete='off'
//                         />
//                     </div>
//                     <Spin spinning={loading} wrapperClassName="mt-[36px] xl:mt-[48px] 2xl:mt-[100px] rounded-[110px] overflow-hidden">
//                       <button className='login-button' onClick={() => {onSubmit()}}>SEND</button>
//                     </Spin>
//                 </div>
//             </div>
//         </Col>
//         <Col xxl={13} xl={13} lg={13} md={13} sm={0} xs={0} className='right-col p-[15px] md:p-[30px]'>
//           <CarouselLoginAndRegister onIndexChange={(value) => {setIndex(value)}} />
//         </Col>
//       </Row>
//     </div>
//   </>
// }
// export default ResetPassword

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LOGO from "../../../assets/images/LOGO.svg";
import AuthBackground from "../../../assets/images/AuthBackground.png";
import LoginFooterImage1 from "../../../assets/images/login/LoginFooterImage1.png";
import LoginFooterImage2 from "../../../assets/images/login/LoginFooterImage2.png";
import LoginFooterImage3 from "../../../assets/images/login/LoginFooterImage3.png";
import LoginFooterImage4 from "../../../assets/images/login/LoginFooterImage4.png";
import LoginFooterImage5 from "../../../assets/images/login/LoginFooterImage5.png";
import LoginFooterImage6 from "../../../assets/images/login/LoginFooterImage6.png";
// import LOGO from "../../../assets/images/LOGO.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ring } from "ldrs";
import routesConstant from "../../../routes/routesConstant";
import { notification } from "antd";
import _ from "lodash";
import authApi from "../../../api/auth.api";
import { BsPatchCheck } from "react-icons/bs";
import RetailerAuthFooter from "../../../components/retailerComponents/retailerAuthFooter/RetailerAuthFooter";

const ResetPassword = () => {
  ring.register();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, setCount] = useState(5);
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    if (!token) {
      notification.warning({
        message: "Can't find your token.",
      });
      return;
    }
  }, []);

  const onSubmit = (values) => {
    console.log(values);
    if (!values.password1 || !values.password2) {
      notification.warning({
        message: "One or more fields is invalid.",
      });
      return;
    }
    if (values.password1 !== values.password2) {
      notification.warning({
        message: "Password must be the same.",
      });
      return;
    }

    setLoading(true);
    authApi
      .resetPassword(token, { password: values.password1 })
      .then((rs) => {
        // notification.success({
        //   message: "Reset password successfully!"
        // })
        setSuccess(true);
        setLoading(false);
        startCountdown();
        setTimeout(() => {
          navigate(routesConstant.login.path);
        }, 5000);
      })
      .catch((err) => {
        notification.error({
          message: _.get(
            err,
            ["response", "data", "message"],
            `Can't reset password!`
          ),
        });
        setLoading(false);
      });
  };
  const startCountdown = () => {
    const countdown = (value) => {
      if (value >= 0) {
        setCount(value); // Update the counter
        setTimeout(() => {
          countdown(value - 1); // Decrease the value and call again
        }, 1000); // Call every 1 second
      }
    };

    countdown(count); // Start the countdown from the current counter value
  };

  const validationSchema = Yup.object({
    password1: Yup.string().required("Password is required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password1"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema,
    onSubmit: (values) => {
      //  e.preventDefault()
      onSubmit(values);
    },
  });

  return (
    <>
      <div
      //  className="sm:px-[32px] md:px-[73px] min-h-screen flex justify-center items-center w-full relative"
      className="sm:px-[32px] md:px-[73px] min-h-screen lg:min-h-[1080px] 3xl:min-h-screen  flex justify-center items-center lg:items-baseline 3xl:items-center pt-16  w-full relative"
       >
        <div
          style={{
            backgroundImage: `url(${AuthBackground})`,
            backgroundSize: "cover",
          }}
          className={`login-image-wrapper`}
        >
          <div className="mx-auto pt-[7%] pb-[15%] sm:pb-0 sm:pl-10 sm:pt-10 w-full ">
            <img
              src={LOGO}
              alt=""
              className="h-8 mx-auto sm:mx-0 sm:h-[33px]"
            />
          </div>
          <div className="hidden lg:block">
            <RetailerAuthFooter />
          </div>
        </div>

        <div className="w-full log-box h-[90vh] sm:h-auto sm:w-2/3 lg:w-2/5 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">
          <div className="flex min-h-full flex-col justify-center px-6 pt-4 sm:mb-4 lg:px-8 items-center">
            {success ? (
              <div className=" w-full my-auto sm:my-20 h-auto flex justify-center items-center">
                <div className=" w-2/3">
                  <div className=" flex justify-center items-center">
                    <div className="w-fit h-fit bg-[#1D1D1D] p-2 rounded-[5px]">
                      <BsPatchCheck size={35} color="white" />
                    </div>
                  </div>
                  <div className="text-center">
                    <h1 className="text-2xl text-white font-medium my-3">
                      Success!
                    </h1>
                    <p className="my-5 text-[#D7D4D4] text-sm">
                      Your password has been updated and is secure. You can now
                      log in again.
                    </p>
                    <p className="mt text-xs opacity-80 text-[#D7D4D4] ">
                      Redirecting to login in ..... {count}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" w-full mt-20 sm:mt-0">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
                  <h2 className=" text-center text-xl font-medium mt-4 leading-9 tracking-tight text-white">
                    Create new password
                  </h2>
                </div>

                <div className="mt-10 sm:my-12 sm:mx-auto sm:w-full sm:max-w-xs ">
                  <form className="sm:mb-5" onSubmit={formik.handleSubmit}>
                    <div>
                      <label
                        for="password1"
                        className="block text-sm font-medium leading-6 text-slate-200 text-left"
                      >
                        Enter new Password
                      </label>
                      <div className="mt-2 text-left relative">
                        <input
                          id="password1"
                          name="password1"
                          type={showPassword1 ? "text" : "password"} 
                          placeholder="Enter new Password"
                          value={formik.values.password1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="block w-full rounded-md border-0 pr-16 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                          // className="block w-full rounded-[5px] bg-[#181818] py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:text-[#7A7F84] focus:ring-2 focus:ring-inset"
                        />
                                                       <div
                      onClick={() => setShowPassword1(!showPassword1)}
                      className="absolute right-3 top-2.5 cursor-pointer text-white"
                    >
                      {showPassword1 ? "Hide" : "Show"}
                    </div>
                      
                        {formik.touched.password1 && formik.errors.password1 ? (
                          <span className="text-red-400 text-left text-[10px] font-light">
                            {formik.errors.password1}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={
                        formik.touched.password1 && formik.errors.password1
                          ? `mt-0`
                          : "mt-3"
                      }
                    >
                      <label
                        for="email"
                        className="block text-sm font-medium leading-6 text-slate-200 text-left"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-2 text-left relative">
                        <input
                          id="password2"
                          name="password2"
                          type={showPassword2 ? "text" : "password"} 
                          placeholder="Re-enter New Password"
                          value={formik.values.password2}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="block w-full rounded-md border-0 pr-16 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                          // className="block w-full rounded-[5px] bg-[#181818] py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:text-[#7A7F84] focus:ring-2 focus:ring-inset"
                        />
                                            <div
                      onClick={() => setShowPassword2(!showPassword2)}
                      className="absolute right-3 top-2.5 cursor-pointer text-white"
                    >
                      {showPassword2 ? "Hide" : "Show"}
                    </div>
                        {formik.touched.password2 && formik.errors.password2 ? (
                          <span className="text-red-400 text-left text-[10px] font-light">
                            {formik.errors.password2}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="mt-6 mb-3">
                      <button
                        type="submit"
                        disabled={loading}
                        className="flex w-full mx-auto justify-center rounded-md bg-slate-50 px-3 py-1.5 text-sm font-medium leading-6 text-slate-950 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {loading ? (
                          <div className="flex">
                            <l-ring
                              size="19"
                              stroke="2"
                              bg-opacity="0"
                              speed="2"
                              color="black"
                            ></l-ring>{" "}
                            <span className="ml-2">Loading </span>
                          </div>
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            <div className="mt-auto w-full">
              <hr className="w-full opacity-20 mt-3" />
              <p className="text-xs text-center mt-2 lg:my-3 text-gray-600">
                The most innovative companies supported metadrob
              </p>

              <footer className="flex footer-com flex-col items-center text-centerx mt-auto sm:mt-0">
                <div className="container">
                  <div className="flex justify-evenly">
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage1}
                        className=" rounded-md w-10 md:w-16 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage2}
                        className=" rounded-md w-8 md:w-10 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage3}
                        className=" rounded-md w-9 md:w-12 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage4}
                        className=" rounded-md w-10 md:w-16 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex ">
                      <img
                        src={LoginFooterImage5}
                        className=" rounded-md w-9 md:w-12  object-contain "
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage6}
                        className=" rounded-md w-9 md:w-12 object-contain "
                      />
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
