import React from "react";
import { NavLink } from "react-router-dom";

const SalesDetails = () => {
  return (
    <div className="w-full flex justify-between items-center gap-2 border-b px-2 border-b-[rgba(0,0,0,0.15)] ">
      <div className="flex items-center gap-[20px]">
        <h1 className="text-[16px] font-bold text-[#A3A3A3]">1.</h1>

        <div className="flex flex-col items-start leading-4">
          <h1 className="text-[16px] font-semibold text-black">Store Name</h1>
          <NavLink className="text-[14px] text-[#A3A3A3]">
            store.link.com
          </NavLink>
        </div>
      </div>

      <div className="flex items-center gap-[10px]">
        <h1 className="text-[16px] font-bold text-black">1,200</h1>

        <div className="bg-[#EBFFF3] flex items-center px-[8px] py-[4px] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.00033 11.0837V2.91699M7.00033 2.91699L2.91699 7.00033M7.00033 2.91699L11.0837 7.00033"
              stroke="#16B364"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <h1 className="text-[#16B364]">3%</h1>
        </div>

      </div>
    </div>
  );
};

export default SalesDetails;
