import React from "react";
import "./style.css";
import SalesButton from "./SalesButton";
import SalesDetails from "./SalesDetails";

const RetailerStoreLeaderBoard = () => {
  const buttonData = [
    {
      id: 1,
      text: "Total Revenue",
      direction: "up",
      bgColor: "#DFC6FF",
    },
    {
      id: 2,
      text: "Total Sales",
      direction: "down",
      bgColor: "#E9ECF1",
    },
    {
      id: 3,
      text: "Rating",
      direction: "up",
      bgColor: "#E9ECF1",
    },
  ];

  return (
    <div className="relative circular-chart w-full h-full md:h-[340px]  flex flex-col items-center md:items-start justify-center md:justify-start   bg-white pt-[20px] md:pt-2 pb-[15px] mb:pb-2">
      {/* The heading along with the info svg */}
      <div className="w-full flex justify-between  items-center mb-[15px] md:mb-2 px-3 py-0">
        <div className="flex items-center gap-2">
          <span className="font-bold md:pt-1">Store Leaderboard</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M8.58518 12.75H10.09V8.25H8.58518V12.75ZM9.33759 6.75C9.55078 6.75 9.7296 6.678 9.87406 6.534C10.0185 6.39 10.0905 6.212 10.09 6C10.0895 5.788 10.0173 5.61 9.87331 5.466C9.72935 5.322 9.55078 5.25 9.33759 5.25C9.12441 5.25 8.94584 5.322 8.80188 5.466C8.65792 5.61 8.58568 5.788 8.58518 6C8.58468 6.212 8.65691 6.39025 8.80188 6.53475C8.94684 6.67925 9.12541 6.751 9.33759 6.75ZM9.33759 16.5C8.29676 16.5 7.31862 16.303 6.40319 15.909C5.48775 15.515 4.69145 14.9807 4.01428 14.3062C3.33711 13.6317 2.80114 12.838 2.40638 11.925C2.01161 11.012 1.81398 10.037 1.81348 9C1.81298 7.963 2.01061 6.988 2.40638 6.075C2.80215 5.162 3.33811 4.36825 4.01428 3.69375C4.69045 3.01925 5.48675 2.485 6.40319 2.091C7.31963 1.697 8.29776 1.5 9.33759 1.5C10.3774 1.5 11.3556 1.697 12.272 2.091C13.1884 2.485 13.9847 3.01925 14.6609 3.69375C15.3371 4.36825 15.8733 5.162 16.2696 6.075C16.6658 6.988 16.8632 7.963 16.8617 9C16.8602 10.037 16.6626 11.012 16.2688 11.925C15.875 12.838 15.3391 13.6317 14.6609 14.3062C13.9827 14.9807 13.1864 15.5152 12.272 15.9097C11.3576 16.3042 10.3794 16.501 9.33759 16.5ZM9.33759 15C11.018 15 12.4413 14.4187 13.6075 13.2562C14.7738 12.0937 15.3569 10.675 15.3569 9C15.3569 7.325 14.7738 5.90625 13.6075 4.74375C12.4413 3.58125 11.018 3 9.33759 3C7.65721 3 6.2339 3.58125 5.06766 4.74375C3.90142 5.90625 3.3183 7.325 3.3183 9C3.3183 10.675 3.90142 12.0937 5.06766 13.2562C6.2339 14.4187 7.65721 15 9.33759 15Z"
              fill="#A6A6A6"
            />
          </svg>
        </div>
      </div>

      {/* The heading btns */}
      {/* <div className="flex flex-row"> */}
      <div className="flex space-x-4 md:mt-2">
        {buttonData.map((button) => (
          <SalesButton
            key={button.id}
            text={button.text}
            direction={button.direction}
            bgColor={button.bgColor}
            onClick={() => console.log(`${button.text} button clicked`)}
          />
        ))}
      </div>

      {/* </div> */}
      <div className="w-full mt-[15px] px-[10px] md:mt-5 flex flex-col gap-[10px] hide-scrollbar overflow-auto">
        <SalesDetails />
        <SalesDetails />
        <SalesDetails />
        <SalesDetails />
        <SalesDetails />
        <SalesDetails />
      </div>
    </div>
  );
};

export default RetailerStoreLeaderBoard;
