import { NavLink } from "react-router-dom";
import "./style.css";

const RetailerEngagementTracker = () => {
  return (
    <>
      <NavLink to="/dashboard/heat-map" className="min-h-52 engagement-tracker-card flex flex-col items-start justify-center gap-[12px] pl-[20px] py-6">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.3332 25.3333V6.66663C25.3332 4.45749 23.5423 2.66663 21.3332 2.66663H10.6665C8.45736 2.66663 6.6665 4.45749 6.6665 6.66663V25.3333C6.6665 27.5424 8.45736 29.3333 10.6665 29.3333H21.3332C23.5423 29.3333 25.3332 27.5424 25.3332 25.3333ZM16.9465 24.9466C17.3248 24.5632 17.4353 23.9898 17.2265 23.4933C17.1617 23.3223 17.0669 23.1642 16.9465 23.0266C16.8831 22.9693 16.8164 22.9159 16.7465 22.8666C16.6722 22.8162 16.5915 22.7758 16.5065 22.7466L16.2665 22.6666C15.8227 22.5816 15.366 22.7271 15.0532 23.0533C14.9318 23.1801 14.8366 23.3296 14.7732 23.4933C14.5749 23.9886 14.691 24.5544 15.0682 24.9316C15.4454 25.3088 16.0112 25.4249 16.5065 25.2266C16.6681 25.1591 16.8169 25.0644 16.9465 24.9466ZM22.6665 6.66663C22.6665 5.93025 22.0696 5.33329 21.3332 5.33329H10.6665C9.93012 5.33329 9.33317 5.93025 9.33317 6.66663V25.3333C9.33317 26.0697 9.93012 26.6666 10.6665 26.6666H21.3332C22.0696 26.6666 22.6665 26.0697 22.6665 25.3333V6.66663ZM14.6665 6.66663H17.4932H17.5198C18.2562 6.66663 18.8532 7.26358 18.8532 7.99996C18.8532 8.73634 18.2562 9.33329 17.5198 9.33329H14.6665C13.9301 9.33329 13.3332 8.73634 13.3332 7.99996C13.3332 7.26358 13.9301 6.66663 14.6665 6.66663Z"
              fill="#141414"
            />
          </svg>
        </div>

        <div className="w-[30vw] flex justify-between gap-[12px] items-center mt-[8px]">
          <div className="font-bold text-[16px] text-left leading-6 ">
            Engagement Tracker
          </div>
        </div>

        <div className="text-[14px] pr-10 text-left leading-4">
          <p>Click on this button to open Heatmap.</p>
        </div>
      </NavLink>
    </>
  );
};

export default RetailerEngagementTracker;
