import { useState } from "react";
import "./style.css";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { IoMdClose } from "react-icons/io";
import templateInfoImg from "../../../assets/images/templateInfoModal.png";
import { FaStar, FaHeart } from "react-icons/fa";
export default function TemplateInfo({ open, setOpen }) {
  //   const [open, setOpen] = useState(true)
  if (!open) {
    return null;
  }
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-[999]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
          <DialogPanel
            transition
            className="relative transform overflow-hidden border  rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8  sm:w-full sm:max-w-lg  md:max-w-2xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="mx-auto bg-white px-4 pb-4 pt-5 sm:p-3 sm:pb-1 w-full md:flex gap-5">
              <div className="items-end w-full md:w-1/2">
                <div className="w-full relative">
                  {/* Content visible on mobile devices */}
                  <div className="md:hidden w-full cursor-pointer">
                    <IoMdClose
                      size={20}
                      color="black"
                      className="ml-auto"
                      onClick={() => setOpen(false)}
                    />
                    <div className="flex gap-2 absolute top-8 ml-2 w-full">
                      <div className="flex justify-center border items-center bg-[#EEF0F2] w-3/12 rounded-md">
                        <p className="text-black text-xs font-semibold">New!</p>
                      </div>
                      <div className="flex justify-center border items-center bg-[#FFC403] w-5/12 rounded-md">
                        <p className="text-black text-xs font-semibold">
                          Premium template
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Heart Icon visible on all devices */}
                  <div className="absolute top-8 md:top-3 right-3 flex justify-center items-center w-[32px] h-[32px] bg-gray-300 backdrop-blur opacity-70 rounded-full">
                    <FaHeart size={17} color="red" className="relative z-50" />
                  </div>

                  {/* Image visible on all devices */}
                  <img src={templateInfoImg} className="object-cover w-full" />
                </div>

                <div className="flex justify-center items-center mt-2 gap-1">
                  <div className="rounded-full w-2 h-2 bg-[#D9D9D9]"></div>
                  <div className="rounded-full w-2 h-2 bg-[#D9D9D9]"></div>
                  <div className="rounded-full w-2 h-2 bg-[#040404]"></div>
                  <div className="rounded-full w-2 h-2 bg-[#D9D9D9]"></div>
                  <div className="rounded-full w-2 h-2 bg-[#D9D9D9]"></div>
                </div>
                <div className="flex flex-row md:flex-wrap gap-2 mt-4 cursor-pointer overflow-x-auto md:overflow-x-visible scrollbar-hide">
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Jewellry</p>
                  </div>
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Bags</p>
                  </div>
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Multi-Product</p>
                  </div>
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Minimalistic</p>
                  </div>
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Apparels</p>
                  </div>
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Shoes</p>
                  </div>
                  <div className="bg-[#D9D9D9] px-2 md:py-2 inline-flex justify-center items-center h-[23.77px] rounded-2xl whitespace-nowrap">
                    <p className="text-xs">Fantasy</p>
                  </div>
                </div>
              </div>
              <div className="items-end w-full md:w-1/2">
                <div className="hidden md:flex gap-2 ">
                  <div className="flex justify-center border items-center bg-[#EEF0F2] w-3/12 rounded-md ">
                    <p className="text-black text-xs font-semibold">New!</p>
                  </div>
                  <div className="flex justify-center border items-center bg-[#FFC403] w-10/12 rounded-md ">
                    <p className="text-black text-xs font-semibold">
                      Premium template
                    </p>
                  </div>
                  <div className="w-full cursor-pointer">
                    <IoMdClose
                      size={20}
                      color="black"
                      className="ml-auto"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                </div>
                <div className="w-full mt-4">
                  <h1 className="font-bold text-2xl">Ovalista</h1>
                  <p className="text-[#6E7C87] text-[9px]">
                    Multi Industry Showroom
                  </p>
                </div>
                <div className="flex gap- mt-2 w-[200.2px] h-[20px]">
                  <FaStar color="#FFC403" />
                  <FaStar color="#FFC403" />
                  <FaStar color="#FFC403" />
                  <FaStar color="#FFC403" />
                  <FaStar color="#FFC403" />

                  <p className="text-xs text-[11.2px] ml-2">32 ratings</p>
                </div>
                <hr className="bg-[#5C5C5C] mt-2"></hr>
                <div className="h-1/3 overflow-scroll mt-2 scrollbar-hide">
                  <p>
                    Welcome to Ovalista, the premier virtual showroom designed
                    to elevate your online retail experience. Meticulously
                    crafted, Ovalista combines cutting-edge 3D modeling with
                    intuitive navigation to create a visually stunning and
                    user-friendly environment.
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-[#8E8E8E] text-[9px]">Recommended for</p>
                  <p className="font-medium text-black">50 - 80 Products</p>
                </div>
                <div className="w-full mt-3">
                  <button class="bg-black hover:bg-gray-700 text-white  py-2 px-4 w-full rounded font-semibold">
                    Create Store
                  </button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
