import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import gsap from "gsap";
import { FaChevronDown } from "react-icons/fa"; 


import endLeft from "../../../assets/heatMapTextures/End_Left.jpg";
import endRight from "../../../assets/heatMapTextures/End_Right.jpg";
import entry from "../../../assets/heatMapTextures/Entry.jpg";
import exit from "../../../assets/heatMapTextures/Exit.jpg";
import frontLeft from "../../../assets/heatMapTextures/Front_Left.jpg";
import frontRight from "../../../assets/heatMapTextures/Front_Right.jpg";
import midLeft from "../../../assets/heatMapTextures/Mid_Left.jpg";
import midRight from "../../../assets/heatMapTextures/Mid_Right.jpg";


const PANORAMA_OPTIONS = [
    { image: endLeft, label: "End Left View" },
    { image: endRight, label: "End Right View" },
    { image: entry, label: "Entry View" },
    { image: exit, label: "Exit View" },
    { image: frontLeft, label: "Front Left View" },
    { image: frontRight, label: "Front Right View" },
    { image: midLeft, label: "Mid Left View" },
    { image: midRight, label: "Mid Right View" },
  ];
  
  const RetailerHeatMap = () => {
    const mountRef = useRef(null);
    const [currentTextureIndex, setCurrentTextureIndex] = useState(0);
    const [loading, setLoading] = useState(true); 
    const sphereRef = useRef(null);
    const cameraRef = useRef(null);
    const renderTarget = useRef(null);
    const controlsRef = useRef(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [hoveredOption, setHoveredOption] = useState(null);
    const [dropdownHeight, setDropdownHeight] = useState(0); 
  
    const loadTexture = (imagePath) => {
      return new Promise((resolve, reject) => {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(
          imagePath,
          (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace;
            texture.minFilter = THREE.NearestFilter;
            texture.magFilter = THREE.NearestFilter;
            texture.generateMipmaps = false;
            resolve(texture);
          },
          undefined,
          (error) => reject(error)
        );
      });
    };
  
    useEffect(() => {
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        50,
        window.innerWidth / window.innerHeight,
        0.1,
        10000
      );
      camera.position.set(0, 0, 0.1);
      cameraRef.current = camera;
  
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      mountRef.current.appendChild(renderer.domElement);
  
      renderTarget.current = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight
      );
  
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      controls.enableZoom = false;
      controlsRef.current = controls;
  
      const geometry = new THREE.SphereGeometry(500, 60, 40);
      geometry.scale(-1, 1, 1);
  
      loadTexture(PANORAMA_OPTIONS[currentTextureIndex].image).then(
        (initialTexture) => {
          const material = new THREE.MeshBasicMaterial({ map: initialTexture });
          const sphere = new THREE.Mesh(geometry, material);
          sphereRef.current = sphere;
          scene.add(sphere);
  
          setLoading(false);
  
          const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
          };
          animate();
        }
      );
  
      const handleResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };
      window.addEventListener("resize", handleResize);
  
      return () => {
        if (renderer && mountRef.current) {
          mountRef.current.removeChild(renderer.domElement);
        }
        window.removeEventListener("resize", handleResize);
        geometry.dispose();
      };
    }, []);
  
    const switchTexture = async (index) => {
      if (!sphereRef.current || !cameraRef.current) return;
  
      gsap.to(cameraRef.current, {
        fov: 40, 
        duration: 0.6, 
        onUpdate: () => cameraRef.current.updateProjectionMatrix(), 
      });
  
      try {
        const newTexture = await loadTexture(PANORAMA_OPTIONS[index].image); 
  
        gsap.to(cameraRef.current, {
          fov: 40,
          duration: 0.3,
          onComplete: () => {
            const sphere = sphereRef.current;
            sphere.material.map.dispose();
            sphere.material.map = newTexture;
            sphere.material.needsUpdate = true;
  
            gsap.to(cameraRef.current, {
              fov: 50, 
              duration: 0.6,
              onUpdate: () => cameraRef.current.updateProjectionMatrix(),
            });
          },
        });
  
        setCurrentTextureIndex(index);
        setDropdownOpen(false);
        setDropdownHeight(0);
      } catch (error) {
        console.error("Failed to load texture:", error);
      }
    };
  
    const toggleDropdown = () => {
      setDropdownOpen(!isDropdownOpen);
      setDropdownHeight(isDropdownOpen ? 0 : PANORAMA_OPTIONS.length * 48); 
    };
  
    const handleMouseEnter = (index) => setHoveredOption(index);
    const handleMouseLeave = () => setHoveredOption(null);
  
    return (
      <div>
        {loading && (
          <div style={styles.loaderOverlay}>
            <div style={styles.progressBarContainer}>
              <div style={styles.progressBar}></div>
            </div>
          </div>
        )}
        <div ref={mountRef} style={{ height: "100vh", width: "100vw" }} />
        {!loading && (
          <div style={styles.dropdownContainer}>
            <div style={styles.labelContainer}>
              <label style={styles.label}>Explore Spaces:</label>
              <div style={styles.selectWrapper} onClick={toggleDropdown}>
                <div style={styles.selectedOption}>
                  {PANORAMA_OPTIONS[currentTextureIndex].label}
                  <FaChevronDown style={styles.arrowIcon} />
                </div>
                <div
                  style={{
                    ...styles.optionsContainer,
                    maxHeight: `${dropdownHeight}px`, 
                  }}
                >
                  {PANORAMA_OPTIONS.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        ...styles.option,
                        ...(hoveredOption === index ? styles.optionHover : {}),
                      }}
                      onClick={() => switchTexture(index)}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  const styles = {
    loaderOverlay: {
      position: "fixed",
      top: 10,
      left: 10,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#000", 
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    progressBarContainer: {
      width: "200px",
      height: "8px",
      backgroundColor: "#333", 
      borderRadius: "4px",
      overflow: "hidden",
    },
    progressBar: {
      width: "100%", 
      height: "100%",
      backgroundColor: "#26282B", 
      animation: "loadProgress 1.5s infinite",
    },
    "@keyframes loadProgress": {
      "0%": { width: "0%" },
      "100%": { width: "100%" },
    },
    dropdownContainer: {
      color: "#000",
      position: "absolute",
      top: 10,
      left: 10,
      zIndex: 1000,
      fontFamily: '"Roboto", "Arial", sans-serif', 
    },
    labelContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    label: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "600",
      marginBottom: "4px",
    },
    selectWrapper: {
      position: "relative",
      width: "200px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      backgroundColor: "#fff",
      cursor: "pointer",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
    },
    selectedOption: {
      padding: "10px 15px",
      fontSize: "16px",
      fontWeight: "500",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    arrowIcon: {
      marginLeft: "10px",
    },
    optionsContainer: {
      overflow: "hidden",
      transition: "max-height 0.3s ease",
    },
    option: {
      padding: "10px 15px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "500",
      textAlign: "left",
      transition: "background-color 0.2s ease",
    },
    optionHover: {
      backgroundColor: "#f0f0f0",
    },
  };

export default RetailerHeatMap
