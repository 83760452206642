import React, { useEffect } from "react";

const GreatJobModal = ({onboardingOver}) => {

    useEffect(()=>{
        const timer = setTimeout(() => {
            onboardingOver();
          }, 3000);
      
         
          return () => clearTimeout(timer);
    },[])

    
  const modalStyle = {
    position: "fixed", // Fixed positioning to center the modal on the screen
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Centers the modal both horizontally and vertically
    width: "400px",
    maxWidth: "90%", // Ensures responsiveness for smaller screens
    padding: "20px",
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark translucent background
    borderRadius: "20px", // Rounded corners
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    color: "#fff",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Light shadow for depth
    lineHeight: "1.5", // Line height to make the text more readable
  };

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: "16px",
    color: "#ddd", // Light gray for the description text
    width: "80%", // Set a width for better text wrapping
    margin: "0 auto", // Center the text
    lineHeight: "1.4", // Ensure proper line height for readability
  };

  return (
    <div style={modalStyle} id="greatJobModal">
      <div style={titleStyle}>Great job!</div>
      <div style={descriptionStyle}>
        You've mastered dragging and dropping objects.
      </div>
    </div>
  );
};

export default GreatJobModal;
