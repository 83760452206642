import { Col, Row, Select, Checkbox, Grid } from "antd";
import "./styles.scss";
import RetailerGrossIncome from "../../../components/retailerComponents/retailerGrossIncome/RetailerGrossIncome";
import RetailerNoOfStores from "../../../components/retailerComponents/retailerNoOfStores/RetailerNoOfStores";
import NewUsers from "../../../components/adminComponents/newUsers/NewUsers";
import RetailerLoginActivity from "../../../components/retailerComponents/retailerLoginActivity/RetailerLoginActivity";
import RetailerTimeSpentOnExploringStore from "../../../components/retailerComponents/retailerTimeSpentOnExploringStore/RetailerTimeSpentOnExploringStore";
import RetailerHeatMapCard from "../../../components/retailerComponents/retailerHeatMapCard/RetailerHeatMapCard";
import RetailerLast5OrdersCard from "../../../components/retailerComponents/retailerLast5OrdersCard/RetailerLast5OrdersCard";
import RetailerProductOfTheMonthCard from "../../../components/retailerComponents/retailerProductOfTheMonthCard/RetailerProductOfTheMonthCard";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRun } from "../../../redux/joyrideSlice";
import RetailerStorageAnalysis from "../../../components/retailerComponents/retailerStorageAnalysis/RetailerStorageAnalysis";
import { useTranslation } from "react-i18next";
import RetailerTargetRevenue from "../../../components/retailerComponents/retailerTargetRevenue/RetailerTargetRevenues";
import RetailerEngagementTracker from "../../../components/retailerComponents/retailerEngagementTracker/RetailerEngagementTracker";
import RetailerCircularChart from "../retailerCircularChart/RetailerCircularChart";
import RetailerStoreLeaderBoard from "../retailerStoreLeaderBoard/RetailerStoreLeaderBoard";
import RetailerFunnelChart from "../retailerFunnelChart/RetailerFunnelChart";
import RetailerBarChart from "../retailerBarChart/RetailerBarChart";
import RetailerRetentionLineChart from "../retailerRetentionLineChart/RetailerRetentionLineChart";
import RetailerFeedbackMeter from "../retailerFeedbackMeter/RetailerFeedbackMeter";
import RetailerWishlist from "../retailerWishlist/RetailerWishlist";
import RetailerSalesAnalysis from "../retailerSalesAnalysis/RetailerSalesAnalysis";
import RetailerHeroProduct from "../retailerHeroProduct/RetailerHeroProduct";
import RetailerOrderHistory from "../retailerOrderHistory/RetailerOrderHistory";
import RetailerNoOfProduct from "../retailerNoOfProduct/RetailerNoOfProduct";
import NoOfProductDesktop from "../NoOfProductDesktop/NoOfProductDesktop";
import RetailerTimeSpentDesktop from "../RetailerTimeSpentDesktop/RetailerTimeSpentDesktop";
import RetailerGrossIncomeDesktop from "../RetailerGrossIncomeDesktop/RetailerGrossIncomeDesktop";
import RetailerUniqueUserDesktop from "../RetailerUniqueUserDesktop/RetailerUniqueUserDesktop";
import RetailerHeatMap from "../../../components/retailerComponents/retailerHeatMap/RetailerHeatMap";
import EngagementTrackerHeatMap from "./../../../components/retailerComponents/EngagementTrackerHeatMap/EngagementTrackerHeatMap";
import StoreSelector from "../StoreOnDesktop/StoreSelector";

const { useBreakpoint } = Grid;

const RetailerHomePage = () => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setRun(true));
  }, [dispatch]);

  const [selectedStore, setSelectedStore] = useState(null);

  const handleStoreSelect = (value) => {
    setSelectedStore(value);
  };

  const storeOptions = [
    { value: "store1", label: "Store 1" },
    { value: "store2", label: "Store 2" },
    { value: "store3", label: "Store 3" },
  ];

  return (
    <div className="retailer-home-page-container template-page bg-white overflow-x-hidden">
      <Row className="px-[18px] md:px-[47px] pb-[40px]">
        <Col span={24}>
          <div className="flex items-center justify-between gap-[20px] flex-wrap mt-[32px] mb-[16px] md:mt-[45px] md:mb-[45px]">
            <div className="text-welcome w-full text-[20px]  flex flex-col items-start gap-4">
              <StoreSelector />

              {/* <Select
                className="retailer-select-timeframe"
                value={selectedStore}
                onChange={handleStoreSelect}
                style={{ width: 120 }}
                placeholder="Store Name"
                options={storeOptions.map((store) => ({
                  value: store.value,
                  label: (
                    <div>
                      <Checkbox checked={selectedStore === store.value} />
                      <span style={{ marginLeft: 8 }}>{store.label}</span>
                    </div>
                  ),
                }))}
              /> */}
            </div>
          </div>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 28]}>
            {screens.lg ? (
              //desktop Layout
              <>
                 <Col span={24}>
          <Row gutter={[16, 28]}>
            {/* First Row */}
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerTargetRevenue />
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <NoOfProductDesktop />
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <RetailerUniqueUserDesktop />
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <RetailerGrossIncomeDesktop />
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <RetailerTimeSpentDesktop />
            </Col>
          </Row>
        </Col>

        {/* Second Row */}
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerStoreLeaderBoard />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerCircularChart />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerSalesAnalysis />
            </Col>
          </Row>
        </Col>

        {/* Third Row */}
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerWishlist />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerBarChart />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <RetailerRetentionLineChart />
            </Col>
          </Row>
        </Col>

        {/* Fourth Row */}
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <RetailerFeedbackMeter />
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <RetailerOrderHistory />
            </Col>
          </Row>
        </Col>

        {/* Fifth Row */}
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <RetailerHeroProduct />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <RetailerFunnelChart />
            </Col>
          </Row>
        </Col>
              </>
            ) : (
              <>
                <Col lg={9} span={24}>
                  <RetailerTargetRevenue />
                </Col>

                <Col lg={15} span={24}>
                  <Row gutter={[16, 16]} className="h-full">
                    <Col lg={12} md={12} span={12} className="h-full">
                      <NewUsers />
                    </Col>
                    <Col lg={12} md={12} span={12} className="h-full">
                      <RetailerGrossIncome />
                    </Col>
                  </Row>
                </Col>

                <Col lg={15} span={24}>
                  <Row gutter={[16, 16]} className="h-full">
                    <Col lg={12} md={12} span={12} className="h-full">
                      <RetailerTimeSpentOnExploringStore />
                    </Col>
                    <Col lg={12} md={12} span={12} className="h-full">
                      <RetailerEngagementTracker />
                    </Col>
                  </Row>
                </Col>

                <Col lg={9} span={24}>
                  <RetailerNoOfProduct />
                </Col>

                <Col lg={6} md={12} span={24}>
                  <RetailerCircularChart />
                </Col>

                <Col lg={6} md={12} span={24} id="hello">
                  <RetailerStoreLeaderBoard />
                </Col>

                <Col lg={6} md={12} span={24}>
                  <RetailerFunnelChart />
                </Col>

                <Col lg={6} md={12} span={24}>
                  <RetailerBarChart />
                </Col>

                <Col lg={6} md={12} span={24}>
                  <RetailerHeroProduct />
                </Col>

                <Col lg={6} md={12} span={24} id="hello">
                  <RetailerRetentionLineChart />
                </Col>

                <Col lg={6} md={12} span={24} id="hello">
                  <RetailerFeedbackMeter />
                </Col>

                <Col lg={6} md={12} span={24} id="hello">
                  <RetailerSalesAnalysis />
                </Col>

                <Col lg={6} md={12} span={24} id="hello">
                  <RetailerWishlist />
                </Col>

                <Col lg={6} md={12} span={24} id="hello">
                  <RetailerOrderHistory />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <div></div>
    </div>
  );
};

export default RetailerHomePage;
