import { getUserAnalytics } from "../../../utils/storage";


export const handleEventOccurance = (id, component_name,event_type, user) => {
   
    const userData=getUserAnalytics()

    
    const sessionId = userData?.data?.session?.id
    const userId=userData?.data?.user?.userId
    const element = document.getElementById(id);
    let x_axis;
    let y_axis;
    if (element) {
        const rect = element.getBoundingClientRect();
        x_axis = rect.left + window.scrollX;
        y_axis = rect.top + window.scrollY;
    }

    if(!userId){
        
        return false
    }
    if (!sessionId) {
       
        return false
    } else {
        const data = {
            userId:userId,
            component_name,
            event_type,
            sessionId,
            x_axis,
            y_axis
        }
        return data
        //setUpdateUserEvents(data)
        //setCheckComponentClick(true);
    }

}