import { Progress, Select } from "antd";
import GrowingUpIcon from "../../../assets/images/admin/growing-up.svg";
import GrowingDownIcon from "../../../assets/images/admin/growing-down.svg";
import { useEffect, useState } from "react";
import orderApi from "../../../api/order.api";
import _ from "lodash";
import { kFormatter } from "../../../utils/util";
import { useTranslation } from "react-i18next";

const RetailerTargetRevenues = () => {
  const [data, setData] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    orderApi.getRetailerGrossIncome().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <>
      <div className="statistical-card">
        <div className="flex justify-between items-center">
          <p>Target Revenue</p>
          <p>60% left</p>
        </div>
        <div className="mt-[5px]">
          <Progress
            className=""
            percent={20} // Set the static percentage to 40%
            key={40} // Ensure the key is updated accordingly
            showInfo={false}
            strokeColor={40 === 40 ? "#9747FF" : "#DCC0FF"} // Red color for 40%
            trailColor="#DCC0FF"
          />
        </div>
        <div className="flex gap-8 font-semibold text-[16px] w-full">
          <p className="font-semibold">0</p>
          <p className="font-semibold">20K</p>
          <p className="font-semibold ml-auto">100K</p>{" "}
          {/* Added ml-auto to move this to the right */}
        </div>
      </div>
    </>
  );
};
export default RetailerTargetRevenues;
