import React from "react";
import PropTypes from "prop-types";

const SalesButton = (props) => {
  const { text, direction, bgColor, onClick } = props;

  return (
    <button
      className={`sales-button flex items-center text-[12px] gap-4 px-[8px] py-[5px] md:py-1 md:px-1 md:ml-2 rounded-[20px]`}
      style={{ backgroundColor: bgColor,display:"inline-block" }}
      onClick={onClick}
    >
      {text}
      {direction === "up" ? "↑" : "↓"}
    </button>
  );
};

SalesButton.propTypes = {
  text: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["up", "down"]).isRequired,
  onClick: PropTypes.func,
};

export default SalesButton;
