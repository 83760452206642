
import LineChart from "../../lineChart/LineChart"
import ArrowUpIcon from "../../../assets/images/admin/arrow-up.svg"
import ArrowDownIcon from "../../../assets/images/admin/arrow-down.svg"
import { useEffect, useState } from "react"
import { userApi } from "../../../api/user.api"
import { kFormatter } from "../../../utils/util"
import global from "../../../redux/global"
import { useTranslation } from "react-i18next"

const NewUsers = () => {
    const [data, setData] = useState()
    const [chartData, setChartData] = useState()
    const { t } = useTranslation()
    useEffect(() => {
        if(!global.IS_DROB_A){
            userApi.countNewUsers().then(data => {
                setData(data)
    
                if(data){
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;
                    const currentYear = currentDate.getFullYear();
    
                    let lastMonth = currentMonth > 1 ? currentMonth - 1 : 12;
                    let yearOfLastMonth = lastMonth === 12 ? currentYear - 1 : currentYear;
    
                    setChartData({
                        labels: [`${lastMonth}/${yearOfLastMonth}`, `${currentMonth}/${currentYear}`],
                        values: [data.lastMonth, data.thisMonth]
                    })
                }
            })
        }
    },[])

    return <>
        <div className="new-users-text flex items-center gap-[20px] pl-[20px] py-5">
        <div className="flex flex-col gap-[15px] items-start">
          <div className="w-[28vw] mt-[5px] font-bold text-[16px]">
            {/* {t('global.new_users')} */}
            Unique Users
          </div>

          <div className="font-bold text-[20px] flex gap-[12px] items-center pl-1">
          <p className="text-[#141414] font-inter text-lg font-bold leading-normal">1498</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M29.7451 6.33333V11.6667C29.7451 12.403 29.1481 13 28.4118 13C27.6754 13 27.0784 12.403 27.0784 11.6667V9.69333L18.7451 19.2133C18.4923 19.501 18.1281 19.6662 17.7451 19.6667C17.3749 19.6761 17.0175 19.5312 16.7584 19.2667L13.7718 16.2533L5.37176 25.88C5.06425 26.2618 4.57204 26.444 4.09004 26.3546C3.60804 26.2651 3.21402 25.9184 3.064 25.4517C2.91398 24.9849 3.03217 24.4736 3.37176 24.12L12.7051 13.4533C12.9478 13.1752 13.295 13.0102 13.664 12.9976C14.0329 12.9849 14.3906 13.1258 14.6518 13.3867L17.7451 16.3733L25.3051 7.66667H23.0784C22.342 7.66667 21.7451 7.06971 21.7451 6.33333C21.7451 5.59695 22.342 5 23.0784 5H28.4118C29.1481 5 29.7451 5.59695 29.7451 6.33333Z"
                fill="#141414"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
}
export default NewUsers;