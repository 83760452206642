import { Progress, Select } from "antd";
import GrowingUpIcon from "../../../assets/images/admin/growing-up.svg";
import GrowingDownIcon from "../../../assets/images/admin/growing-down.svg";
import { useEffect, useState } from "react";
import orderApi from "../../../api/order.api";
import _ from "lodash";
import { kFormatter } from "../../../utils/util";
import { useTranslation } from "react-i18next";

const NoOfProductDesktop = () => {
  return (
    <>
      <div className="stastical rounded-[12px] h-auto aspect-[144/100] p-2 gap-[12px] pb-3">
        <div className="flex justify-between items-center mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M15 1.875L28 8.375V24.625L15 31.1094L2 24.625V8.375L15 1.875ZM24.7656 9L15 4.125L11.2344 6L20.9375 10.9062L24.7656 9ZM15 13.875L18.7188 12.0312L9 7.125L5.23438 9L15 13.875ZM4 10.625V23.375L14 28.375V15.625L4 10.625ZM16 28.375L26 23.375V10.625L16 15.625V28.375Z"
              fill="black"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            class="mb-2"
          >
            <path
              d="M9.21897 12.75H10.7238V8.25H9.21897V12.75ZM9.97138 6.75C10.1846 6.75 10.3634 6.678 10.5079 6.534C10.6523 6.39 10.7243 6.212 10.7238 6C10.7233 5.788 10.6511 5.61 10.5071 5.466C10.3631 5.322 10.1846 5.25 9.97138 5.25C9.7582 5.25 9.57963 5.322 9.43567 5.466C9.2917 5.61 9.21947 5.788 9.21897 6C9.21847 6.212 9.2907 6.39025 9.43567 6.53475C9.58063 6.67925 9.7592 6.751 9.97138 6.75ZM9.97138 16.5C8.93055 16.5 7.95241 16.303 7.03698 15.909C6.12154 15.515 5.32524 14.9807 4.64807 14.3062C3.9709 13.6317 3.43493 12.838 3.04017 11.925C2.6454 11.012 2.44777 10.037 2.44727 9C2.44676 7.963 2.6444 6.988 3.04017 6.075C3.43594 5.162 3.9719 4.36825 4.64807 3.69375C5.32424 3.01925 6.12054 2.485 7.03698 2.091C7.95341 1.697 8.93155 1.5 9.97138 1.5C11.0112 1.5 11.9894 1.697 12.9058 2.091C13.8222 2.485 14.6185 3.01925 15.2947 3.69375C15.9709 4.36825 16.5071 5.162 16.9034 6.075C17.2996 6.988 17.497 7.963 17.4955 9C17.494 10.037 17.2964 11.012 16.9026 11.925C16.5088 12.838 15.9729 13.6317 15.2947 14.3062C14.6165 14.9807 13.8202 15.5152 12.9058 15.9097C11.9914 16.3042 11.0132 16.501 9.97138 16.5ZM9.97138 15C11.6518 15 13.0751 14.4187 14.2413 13.2562C15.4076 12.0937 15.9907 10.675 15.9907 9C15.9907 7.325 15.4076 5.90625 14.2413 4.74375C13.0751 3.58125 11.6518 3 9.97138 3C8.291 3 6.86768 3.58125 5.70145 4.74375C4.53521 5.90625 3.95209 7.325 3.95209 9C3.95209 10.675 4.53521 12.0937 5.70145 13.2562C6.86768 14.4187 8.291 15 9.97138 15Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="flex flex-col items-start gap-[12px]">
          <h3 className="text-black inline-block text-[14px] font-medium">
            No. of Products
          </h3>
          <h1 className="text-black  text-[20px] font-bold">
            1234
          </h1>
        </div>
      </div>
    </>
  );
};
export default NoOfProductDesktop;
