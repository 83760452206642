import { useState } from "react";
import LoginFooterImage1 from "../../../assets/images/login/LoginFooterImage1.png";
import LoginFooterImage2 from "../../../assets/images/login/LoginFooterImage2.png";
import LoginFooterImage3 from "../../../assets/images/login/LoginFooterImage3.png";
import LoginFooterImage4 from "../../../assets/images/login/LoginFooterImage4.png";
import LoginFooterImage5 from "../../../assets/images/login/LoginFooterImage5.png";
import LoginFooterImage6 from "../../../assets/images/login/LoginFooterImage6.png";
import { notification } from "antd";


const EnterNameComponent=({name,setName,setPart})=>{
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit=(e)=>{
      e.preventDefault()
      if(!name){
        notification.warning({
            message: "Kindly Add your name!",
            className: "z-[100000]"
        })
        return;
    }
    setPart(5)
    }
    return (
        <>
           <div className="w-full log-box h-[85vh] sm:h-auto sm:w-2/3 lg:w-4/12 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">



<div className="flex min-h-full flex-col justify-center items-center px-6 pt-4 sm:mb-4 lg:px-8  ">

<div className="w-full  min-h-48  p-5 flex justify-center items-center text-center flex-col gap-4">
<h1 className="font-bold text-white text-[30px]">Experience the power of </h1>
 <span className="bg-gradient-to-r from-[#24ddfe] to-[#578bfe] bg-clip-text text-transparent text-[30px] font-bold h-7">Virtual Shopping</span>  
 <p className="text-white font-light flex  text-[15px]">Powered by <span className="bg-gradient-to-r from-[#24ddfe] to-[#578bfe] bg-clip-text text-transparent font-bold pl-2">Metadrob</span></p>     
</div>
<div className="w-full min-h-52 flex justify-center items-center">
<form className="w-8/12  " >
                <div className="w-full  ">
                  <label for="name" className="block text-sm font-medium leading-6 text-slate-200 text-left">Name</label>
                  <div className="mt-2 text-left">
                    <input id="name" name="name" type="text" autocomplete="name" placeholder="Enter name"
                      value={name} onChange={(e) => {setName(e.target.value)}}
                      className="block w-full rounded-md border-0 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                    // className="block w-full rounded-[5px] bg-[#181818] py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:text-[#7A7F84] focus:ring-2 focus:ring-inset"
                    />
                  </div>

                </div>
              
            

                <div className="mt-3 w-full" >
                  <button 
                    onClick={handleSubmit}
                    disabled={isLoading}

                    className="flex w-full  mx-auto justify-center rounded-md bg-slate-50 px-3 py-1.5 text-sm font-semibold leading-6 text-slate-950 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {isLoading ? <div className="flex"><l-ring size="19"
                      stroke="2"
                      bg-opacity="0"
                      speed="2"
                      color="black" 
                    ></l-ring> <span className="ml-2" >Loading </span></div> : "Enter store"}
                  </button>
                </div>
              </form>
</div>

  
  <div className="mt-auto w-full p-2" >
    <hr className="w-full opacity-20 mt-3" />
    <p className="text-xs text-center mt-2 lg:my-3 text-gray-600">
      The most innovative companies supported metadrob
    </p>

    <footer
      className="flex footer-com flex-col items-center text-centerx mt-auto sm:mt-0">
      <div className="container">
        <div className="flex justify-evenly">
          <div className="mb-6 lg:mb-0 flex">
            <img
              src={LoginFooterImage1}
              className=" rounded-md w-10 md:w-16 object-contain" />
          </div>
          <div className="mb-6 lg:mb-0 flex">
            <img
              src={LoginFooterImage2}
              className=" rounded-md w-8 md:w-10 object-contain" />
          </div>
          <div className="mb-6 lg:mb-0 flex">
            <img
              src={LoginFooterImage3}
              className=" rounded-md w-9 md:w-12 object-contain" />
          </div>
          <div className="mb-6 lg:mb-0 flex">
            <img
              src={LoginFooterImage4}
              className=" rounded-md w-10 md:w-16 object-contain" />
          </div>
          <div className="mb-6 lg:mb-0 flex ">
            <img
              src={LoginFooterImage5}
              className=" rounded-md w-9 md:w-12  object-contain " />
          </div>
          <div className="mb-6 lg:mb-0 flex">
            <img
              src={LoginFooterImage6}
              className=" rounded-md w-9 md:w-12 object-contain " />
          </div>
        </div>
      </div>

    </footer>

  </div>
</div>
</div>

        </>
    )
}

export default EnterNameComponent