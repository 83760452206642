// import { useEffect } from "react";
import "./retailerPlans.css"

import { NavLink } from "react-router-dom";
// import { IoClose } from "react-icons/io5";
// import { Modal, Row, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import { CONFIG_TEXT, PAYMENT_GATE, PAYMENT_STATUS, PLANS_TYPES, PRICING_PLAN_FEATURES_KEY, TRIAL_EXPIRED_DAYS, USER_SUBCRIPTION_KEY } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { getIsViewerMode } from "../../../redux/modelSlice"; 
import { getUser, setUser } from "../../../redux/appSlice";
import { userApi } from "../../../api/user.api"; 
import { useNavigate } from "react-router-dom";

import moment from "moment";
import userSubcriptionApi from "../../../api/userSubcription.api";
import { useAppDispatch } from "../../../redux"; 
import _ from "lodash";
// import MetadrobPricingPlans from "./components/metadrobPricingPlans/MetadrobPricingPlans";
// import DrobAPricingPlans from "./components/drobAPricingPlans/DrobAPricingPlans";
// import Lottie from "lottie-react";
// import loadingAnimation from "../../assets/json/Add Products.json"
// import ModalPaypalForPricingPlan from "./components/modalPaypalForPricingPlan/ModalPaypalForPricingPlan";
import global from "../../../redux/global";
import { useAuthenticatedFetch } from "../../../modules/shopify/hooks";
import { useParams } from "react-router-dom";
import { useAppBridgeRedirect } from "../../../modules/shopify/hooks/useAuthenticatedFetch";
import { isMobile } from "react-device-detect";
// import ModalExitIcon from "../../assets/images/project/modal-exit.svg"
import AnalyticsApiHelper from "../../../hook/AnalyticsApiHelper";
import { handleEventOccurance } from "../retailerHelper/analyticsEventHandler"; 
// import "./modelPrice.css"
import { FaCircleCheck } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { HiX } from "react-icons/hi";
import pricingPlanApi from "../../../api/pricingPlan.api";
import { notification } from "antd";
import ModalPaypalForPricingPlan from "../../../components/modalPricingPlan/components/modalPaypalForPricingPlan/ModalPaypalForPricingPlan";

const RetailerPlans = ({isPublishProject = false,onClose = () => { }}) => {
  useEffect(()=>{
    localStorage.setItem("isIn", JSON.stringify(true));

    return(()=>{
      localStorage.removeItem("isIn");
    })
  })
  const pricingData = [
    {
      id: 1,
      heading: "Subscription Plans Overview",
      plans: {
        name1: "Annual Pricing",
        name2: "Monthly Pricing",
        name3: "Free Trial (Days)",
        smart1: "$7,999",
        smart2: "$800",
        smart3: "7",
        pro1: "$11,999",
        pro2: "$1200",
        pro3: "7",
        premium1: "$15,999",
        premium2: "$1600",
        premium3: "14",
      },
    },

    {
      id: 2,
      heading: "Showrooms & Stores",
      plans: {
        name1: "Published Showrooms (Web)",
        name2: "Number of Draft Stores",
        name3: "Showroom Expansion Availability",
        smart1: "1",
        smart2: "3",
        smart3: "✖",
        pro1: "1",
        pro2: "5",
        pro3: "✅",
        premium1: "1 + 1 (Handheld)",
        premium2: "10",
        premium3: "✅",
      },
    },

    {
      id: 3,
      heading: "Products & Media",
      plans: {
        name1: "Number of Products",
        name2: "Product Media Files",
        name3: "Catalogue (3D Files per Product)",
        name4: "Catalogue (Videos per Product)",
        name5: "Decorative Items in Store",
        name6: "Decorative Items Availability",
        name7: "Bulk Product Import Availability",
        smart1: "10",
        smart2: "2D & 3D",
        smart3: "1",
        smart4: "1",
        smart5: "5",
        smart6: "Basic",
        smart7: "✖",
        pro1: "30",
        pro2: "2D & 3D",
        pro3: "3",
        pro4: "3",
        pro5: "10",
        pro6: "Basic + Premium",
        pro7: "✅",
        premium1: "50",
        premium2: "2D & 3D",
        premium3: "5",
        premium4: "5",
        premium5: "15",
        premium6: "Basic + Premium",
        premium7: "✅",
      },
    },

    {
      id: 4,
      heading: "Avatars & Customer Experience",
      plans: {
        name1: "Differentiated Avatars (Customers)",
        name2: "Differentiated Avatars (Sales Team)",
        name3: "Multi-Customer Experience (Spatial Audio)	",
        name4: "Multi-Customer Limit per Room",
        smart1: "1",
        smart2: "1",
        smart3: "✖",
        smart4: "-",
        pro1: "2",
        pro2: "2",
        pro3: "✅",
        pro4: "",
        premium1: "4",
        premium2: "4",
        premium3: "✅",
        premium4: "10",
      },
    },

    {
      id: 5,
      heading: "Room & Waiting Experience",
      plans: {
        name1: "Room Limit",
        name2: "Waiting Room",
        smart1: "-",
        smart2: "✖",
        pro1: "32",
        pro2: "Standard",
        premium1: "30",
        premium2: "Custom",
      },
    },

    {
      id: 6,
      heading: "File & Storage Limits",
      plans: {
        name1: "2D File Size Limit (MB)",
        name2: "3D File Size Limit (MB)",
        name3: "Video File Size Limit (MB)",
        name4: "Storage Size",
        smart1: "2",
        smart2: "5",
        smart3: "5",
        smart4: "100 MB",
        pro1: "3",
        pro2: "10",
        pro3: "10",
        pro4: "250 MB",
        premium1: "5",
        premium2: "15",
        premium3: "30",
        premium4: "1 GB",
      },
    },

    {
      id: 7,
      heading: "Support & Customization",
      plans: {
        name1: "Store Success Support",
        name2: "Account Support",
        name3: "Showroom White Labelling",
        smart1: "✖",
        smart2: "Email Only",
        smart3: "✖",
        pro1: "✅",
        pro2: "Email & Chat",
        pro3: "✖",
        premium1: "✅",
        premium2: "Email, Chat, Phone & VC",
        premium3: "✅",
      },
    },

    {
      id: 8,
      heading: "Device Compatibility & Interactivity",
      plans: {
        name1: "Mobile Device Compatibility",
        name2: "Product Animation",
        name3: "View Products in AR",
        name4: "Add Music in Store",
        smart1: "✖",
        smart2: "✖",
        smart3: "✖",
        smart4: "✖",
        pro1: "✖",
        pro2: "✅",
        pro3: "✖",
        pro4: "✖",
        premium1: "✅",
        premium2: "✅",
        premium3: "✅",
        premium4: "✅",
      },
    },

    {
      id: 9,
      heading: "Integration & Analytics",
      plans: {
        name1: "E-commerce Platform Integration",
        name2: "Store Analytics",
        smart1: "✖",
        smart2: "Basic",
        pro1: "✅",
        pro2: "Standard",
        premium1: "✅",
        premium2: "Advanced",
      },
    },
  ];

  const navigate = useNavigate();
  const { id: projectId } = useParams()
  const dispatch = useAppDispatch()
  const [planType, setPlanType] = useState(global.IS_DROB_A ? PLANS_TYPES.DIGITAL_SHOWCASE : PLANS_TYPES.E_COMMERCE)
  const isViewerMode = useSelector(getIsViewerMode)
  const user = useSelector(getUser)

  const [triedPlanIds, setTriedPlanIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [selectedPayPer, setSelectedPayPer] = useState("Monthly")

  const [isShowModalPaypal, setIsShowModalPaypal] = useState(false)
  const [purchasePlanInfo, setPurchasePlanInfo] = useState({
    plan: {},
    total: 0,
    payPer: "Yearly"
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = global.IS_SHOPIFY ? useAuthenticatedFetch() : null
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const appBrigdeRedirect = global.IS_SHOPIFY ? useAppBridgeRedirect() : null

  useEffect(() => {
    if (user?.id) {
      userApi.getListTriedPlanIds(user.id).then(rs => {
        setTriedPlanIds(rs)
      })
    }
  }, [user?.id])

  const onTrialPlan = (plan) => {
    if (!plan) {
      notification.warning({
        message: CONFIG_TEXT.PLEASE_CHOOSE_PLAN
      })
      return
    }
    if (!user?.id) {
      return
    }
    if (!triedPlanIds.includes(plan?.id)) {
      setIsLoading(true)
      const trialDays = _.get(_.find(_.get(plan, ['features'], []), { key: PRICING_PLAN_FEATURES_KEY.TRIAL_PERIOD }), ['value'], TRIAL_EXPIRED_DAYS)

      const body = {
        userId: user.id,
        key: USER_SUBCRIPTION_KEY.PRICING_PLAN,
        value: {
          pricingId: plan?.id,
          stripeIntentSecret: null,
          numOfDate: trialDays,
          createdDate: moment(new Date()),
          expiredDate: moment(new Date()).add(trialDays, 'day'),
          isTrial: true,
          paymentGate: "NONE"
        },
        paymentStatus: PAYMENT_STATUS.SUCCEEDED,
        active: true,
      }
      userSubcriptionApi.createSubcription(body).then(rs => {
        userApi.updateLoggedInUser({
          triedPlanIds: [...triedPlanIds, plan?.id]
        }).then(userData => {
          notification.success({
            message: "Subcribe Trial version!"
          })
          setIsLoading(false)
          dispatch(setUser(userData.user))
          setTriedPlanIds(_.get(userData, ['user', 'triedPlanIds'], []))
          onClose()
        }).catch(err => {
          setIsLoading(false)
          notification.error({
            message: _.get(err, ['response', 'data', 'message'], `Can't update user!`)
          })
        })
      }).catch(err => {
        setIsLoading(false)
        notification.error({
          message: _.get(err, ['response', 'data', 'message'], `Can't subcribe Trial version!`)
        })
      })
    }
  }

  const onPurchasePlan = async (plan, total, payPer) => {

    // Removed this logic for shopify
    if (global.IS_SHOPIFY) {


      setIsLoading(true)
      try {
        const data = await getPurchaseOneTimeUrl(total, plan)
    


        if (_.get(data, ['body', 'data', 'appPurchaseOneTimeCreate', 'userErrors', 'length'], 0)) {
          setIsLoading(false)
          notification.error({
            message: _.get(data, ['body', 'data', 'appPurchaseOneTimeCreate', 'userErrors', '0', 'message'], `Can't create app purchase one time!`)
          })
        } else {
          const confirmationUrl = _.get(data, ['body', 'data', 'appPurchaseOneTimeCreate', 'confirmationUrl'])
          if (confirmationUrl) {
            const appPurchaseOneTime = _.get(data, ['body', 'data', 'appPurchaseOneTimeCreate', 'appPurchaseOneTime'])

            const body = {
              userId: user.id,
              key: USER_SUBCRIPTION_KEY.PRICING_PLAN,
              value: {
                amount: total,
                pricingId: plan.id,
                numOfDate: payPer === "Monthly" ? 30 : 365,
                createdDate: moment(new Date()),
                expiredDate: moment(new Date()).add(payPer === "Monthly" ? 30 : 365, 'day'),
                paymentGate: PAYMENT_GATE.SHOPIFY_BILLING,
                appPurchaseOneTime,
                userData: {
                  isPublishProject,
                  projectId
                }
              }
            }
            const newSubcription = await userSubcriptionApi.createSubcription(body)
            setIsLoading(false)
            if (newSubcription.id) {
              appBrigdeRedirect(confirmationUrl)
            } else {
              notification.error({
                message: `Can't create subcription for ${plan.name}`
              })
            }
          } else {
            setIsLoading(false)
            notification.error({
              message: "Can't retrive confirm url!"
            })
          }
        }
      } catch (err) {
        setIsLoading(false)
      }

    } else {
      setPurchasePlanInfo({
        plan,
        total,
        payPer
      })
      setIsShowModalPaypal(true)
    }
  }

  const getPurchaseOneTimeUrl = async (total, plan) => {
    const body = {
      amount: total,
      currencyCode: "USD",
      name: `Payment for ${_.get(plan, ['name'])}`,
    }

    const response = await fetch("/shopify/get-purchase-one-time-url", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    })
    const data = await response.json()

    return data
  }

  const [checkComponentClick, setCheckComponentClick] = useState(false)
  const [updateUserEvents, setUpdateUserEvents] = useState(null)

  const handleEvent = (type) => {
    const id = type
    const component_name = `${type} Billing`
    const event_type = 'pricingPlan_event'
    const data = handleEventOccurance(id, component_name, event_type, user)
    if (data) {
      setUpdateUserEvents(data)
      setCheckComponentClick(true);
    }

  }

  // const isViewerMode = useSelector(getIsViewerMode)
  const [plans, setPlans] = useState([])
  const [firstPlan, setFirstPlan] = useState([])
  const [secondPlan, setSecondPlan] = useState([])
  const [thirdPlan, setThirdPlan] = useState([])

  useEffect(() => {
    pricingPlanApi.getAvailablePricingPlans({ isDrobA: 0 }).then(rs => {
      setPlans(rs)
      const first = rs?.find(item => item.display === 'first');
      setFirstPlan(first)

      const second = rs?.find(item => item.display === 'second');
      setSecondPlan(second)

      const third = rs?.find(item => item.display === 'third');
      setThirdPlan(third)


    })
  }, [])

  function getHiddenClass(item) {
    return item ? '' : ' hidden-cls'
  }

  return (
    <div className="w-full min-h-screen flex flex-col py-[2vw] px-[3.5vw]">
      <NavLink to="/dashboard/home" className="price-plan-text mb-[1.25vw]">
        <div className="price-plan-text flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="11"
            viewBox="0 0 32 11"
            fill="none"
          >
            <path
              d="M5.86486 1L1 5.5M1 5.5L5.86486 10M1 5.5H19"
              stroke="#595959"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h1 className="text-zinc-600">
            Home/Profile/<span className="price-plan-text">Plans</span>
          </h1>
        </div>
      </NavLink>

      <div className="flex gap-5 text-[1.5vw] items-center price-plan-text mb-[1.7vw]">
        <h1 className="price-plan-text font-bold cursor-pointer">Plans</h1>
        <h1 className="text-zinc-600 font-bold cursor-pointer">
          Billing History
        </h1>
      </div>

      <div className="w-full min-h-32 price-plan-background flex flex-col gap-[1.5vw]">
        <div className="price-plan-text flex justify-between items-center px-[2vw] pt-[1.2vw]">
          {["Features", "Smart", "Pro", "Premium"].map((val, index) => {
            return (
              <h1
                key={index}
                className={`font-bold text-[1vw] ${
                  index === 0 ? "w-[20%] text-left" : "w-[20%] text-center"
                }`}
              >
                {val}
              </h1>
            );
          })}
        </div>

        <div className="flex flex-col gap-4">
          {pricingData.map((price) => (
            <div key={price.id}>
              <div className="w-full flex justify-start items-center pl-[2vw] h-[2vw] price-plan-table-row-background mb-[1vw]">
                <h1 className="font-bold text-[0.8vw] price-plan-text">
                  {price.heading}
                </h1>
              </div>

              {price.plans && (
                <div className="flex flex-col gap-[0.2vw]">
                  <div className="flex justify-between items-center px-[2vw] price-plan-text">
                    <h1 className="w-[20%] text-[0.8vw] font-medium text-left">
                      {price.plans.name1}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.smart1}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.pro1}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.premium1}
                    </h1>
                  </div>

                  <div className="flex justify-between items-center px-[2vw] price-plan-text">
                    <h1 className="w-[20%] text-[0.8vw] font-medium text-left">
                      {price.plans.name2}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.smart2}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.pro2}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.premium2}
                    </h1>
                  </div>

                  <div className="flex justify-between items-center px-[2vw] price-plan-text">
                    <h1 className="w-[20%] text-[0.8vw] font-medium text-left">
                      {price.plans.name3}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.smart3}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.pro3}
                    </h1>
                    <h1 className="w-[20%] text-[1vw] font-bold text-center">
                      {price.plans.premium3}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-between px-[2vw] mb-[2.2vw] ">
          <button  className="bg-white py-[0.5vw] px-[3vw] invisible w-[20%]">
            Get Started
          </button>
          <button onClick={() => {
                      onPurchasePlan(firstPlan, (selectedPayPer === "Monthly" ? _.get(firstPlan, ['pricing', 'monthly']) : _.get(firstPlan, ['pricing', 'yearly'])) * (selectedPayPer === "Monthly" ? 1 : 12), "Yearly")
                    }} className="bg-white py-[0.5vw] px-[3vw] rounded-[0.3vw] cursor-pointer w-[20%] text-center">
            Get Started
          </button>
          <button onClick={() => {
                      onPurchasePlan(secondPlan, (selectedPayPer === "Monthly" ? _.get(secondPlan, ['pricing', 'monthly']) : _.get(secondPlan, ['pricing', 'yearly'])) * (selectedPayPer === "Monthly" ? 1 : 12), "Yearly")
                    }} className="bg-white py-[0.5vw] px-[3vw] rounded-[0.3vw] cursor-pointer w-[20%] text-center">
            Get Started
          </button>
          <button onClick={() => {
                      onPurchasePlan(thirdPlan, (selectedPayPer === "Monthly" ? _.get(thirdPlan, ['pricing', 'monthly']) : _.get(thirdPlan, ['pricing', 'yearly'])) * (selectedPayPer === "Monthly" ? 1 : 12), "Yearly")
                    }} className="bg-white py-[0.5vw] px-[3vw] rounded-[0.3vw] cursor-pointer w-[20%] text-center">
            Get Started
          </button>
        </div>
      </div>
      <ModalPaypalForPricingPlan
      open={isShowModalPaypal}
      purchasePlanInfo={purchasePlanInfo}
      isPublishProject={isPublishProject}
      onClose={() => { setIsShowModalPaypal(false) }}
    />
    </div>
  );
};

export default RetailerPlans;