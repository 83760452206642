import { useEffect, useState } from "react";
import orderApi from "../../../api/order.api";
import _ from "lodash";
import { kFormatter } from "../../../utils/util";
import { useTranslation } from "react-i18next";
import "./style.css";

const RetailerGrossIncome = () => {
  const [data, setData] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    orderApi.getRetailerGrossIncome().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <>
      <div className="gross-income pl-[20px] py-5">
        <div className="flex justify-between items-center">
          <div className="w-[28vw] mt-[5px] font-bold text-[16px]">
            {/* {t("global.gross_income")} */}
            Gross Income
          </div>
        </div>
        <div className="mt-[16px] flex justify-between">
          <div>
            <div className="font-bold text-[20px] flex gap-[12px] items-center pl-1">
            <h2 className="text-[#141414] font-inter text-lg font-bold leading-normal">$179927</h2>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M29.3334 20V25.3333C29.3334 26.0697 28.7365 26.6667 28.0001 26.6667H22.6667C21.9304 26.6667 21.3334 26.0697 21.3334 25.3333C21.3334 24.597 21.9304 24 22.6667 24H24.8934L17.3334 15.2933L14.3334 18.32C14.0718 18.5842 13.7025 18.7123 13.3334 18.6667C12.9645 18.655 12.6169 18.4908 12.3734 18.2133L3.04008 7.54666C2.59235 6.99078 2.6618 6.18088 3.19764 5.70934C3.73348 5.2378 4.54563 5.27188 5.04008 5.78666L13.4401 15.4133L16.3867 12.4C16.6388 12.1307 16.9912 11.9779 17.3601 11.9779C17.7289 11.9779 18.0813 12.1307 18.3334 12.4L26.6667 21.9733V20C26.6667 19.2636 27.2637 18.6667 28.0001 18.6667C28.7365 18.6667 29.3334 19.2636 29.3334 20Z"
                  fill="#141414"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RetailerGrossIncome;
