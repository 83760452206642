import { useState } from "react"
import WalkAndNavigationModal from "./WalkAndNavigationModal";
import WalkAndClickInfoModal from "./WalkAndNavigationInfoModal";
import GreatJobModal from "./GreateJobModal";


const EndUserOnBoarding=({onboardingOver})=>{
    const [step,setSteps]=useState(1)

    const handleNext=()=>{
        setSteps(step +1)
    }


    const componentToRender = (() => {
        switch (step) {
            case 1:
                return <WalkAndNavigationModal handleNext={handleNext} />;
            case 2 :
                return <WalkAndClickInfoModal handleNext={handleNext}/>
            case 3 : 
                return <GreatJobModal onboardingOver={onboardingOver}/>
            default:
                return <WalkAndNavigationModal handleNext={handleNext}/>;
        }
    })();
    return (
        <>

{componentToRender}

        
        
        </>
    )
}

export default EndUserOnBoarding