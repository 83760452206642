import { useEffect, useState } from "react";
import orderApi from "../../../api/order.api";
import _ from "lodash";
import { kFormatter } from "../../../utils/util";
import { useTranslation } from "react-i18next";
import "./style.css";

const RetailerGrossIncomeDesktop = () => {
  const [data, setData] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    orderApi.getRetailerGrossIncome().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <>
    <div>
      <div className="gross-income flex flex-col  p-2 pb-3 gap-[12px] overflow-hidden ">
        <div className="flex justify-between  ">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22" fill="none">
  <path d="M9.25 10.25H7.75V4.25H8.5C9.29565 4.25 10.0587 4.56607 10.6213 5.12868C11.1839 5.69129 11.5 6.45435 11.5 7.25C11.5 7.44891 11.579 7.63968 11.7197 7.78033C11.8603 7.92098 12.0511 8 12.25 8C12.4489 8 12.6397 7.92098 12.7803 7.78033C12.921 7.63968 13 7.44891 13 7.25C12.9988 6.05691 12.5243 4.91303 11.6806 4.06939C10.837 3.22575 9.69309 2.75124 8.5 2.75H7.75V1.25C7.75 1.05109 7.67098 0.860322 7.53033 0.71967C7.38968 0.579018 7.19891 0.5 7 0.5C6.80109 0.5 6.61032 0.579018 6.46967 0.71967C6.32902 0.860322 6.25 1.05109 6.25 1.25V2.75H5.5C4.30653 2.75 3.16193 3.22411 2.31802 4.06802C1.47411 4.91193 1 6.05653 1 7.25C1 8.44347 1.47411 9.58807 2.31802 10.432C3.16193 11.2759 4.30653 11.75 5.5 11.75H6.25V17.75H4.75C3.95435 17.75 3.19129 17.4339 2.62868 16.8713C2.06607 16.3087 1.75 15.5456 1.75 14.75C1.75 14.5511 1.67098 14.3603 1.53033 14.2197C1.38968 14.079 1.19891 14 1 14C0.801088 14 0.610322 14.079 0.46967 14.2197C0.329018 14.3603 0.25 14.5511 0.25 14.75C0.251241 15.9431 0.725745 17.087 1.56939 17.9306C2.41303 18.7743 3.55691 19.2488 4.75 19.25H6.25V20.75C6.25 20.9489 6.32902 21.1397 6.46967 21.2803C6.61032 21.421 6.80109 21.5 7 21.5C7.19891 21.5 7.38968 21.421 7.53033 21.2803C7.67098 21.1397 7.75 20.9489 7.75 20.75V19.25H9.25C10.4435 19.25 11.5881 18.7759 12.432 17.932C13.2759 17.0881 13.75 15.9435 13.75 14.75C13.75 13.5565 13.2759 12.4119 12.432 11.568C11.5881 10.7241 10.4435 10.25 9.25 10.25ZM5.5 10.25C4.70435 10.25 3.94129 9.93393 3.37868 9.37132C2.81607 8.80871 2.5 8.04565 2.5 7.25C2.5 6.45435 2.81607 5.69129 3.37868 5.12868C3.94129 4.56607 4.70435 4.25 5.5 4.25H6.25V10.25H5.5ZM9.25 17.75H7.75V11.75H9.25C10.0456 11.75 10.8087 12.0661 11.3713 12.6287C11.9339 13.1913 12.25 13.9544 12.25 14.75C12.25 15.5456 11.9339 16.3087 11.3713 16.8713C10.8087 17.4339 10.0456 17.75 9.25 17.75Z" fill="black"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M9.84375 9C9.84375 9.16688 9.79426 9.33001 9.70155 9.46876C9.60884 9.60752 9.47706 9.71566 9.32289 9.77952C9.16871 9.84339 8.99906 9.8601 8.83539 9.82754C8.67172 9.79498 8.52138 9.71462 8.40338 9.59662C8.28538 9.47862 8.20502 9.32828 8.17246 9.16461C8.13991 9.00094 8.15662 8.83129 8.22048 8.67711C8.28434 8.52294 8.39248 8.39116 8.53124 8.29845C8.66999 8.20574 8.83312 8.15625 9 8.15625C9.22378 8.15625 9.43839 8.24515 9.59662 8.40338C9.75486 8.56161 9.84375 8.77622 9.84375 9ZM9 5.0625C9.16688 5.0625 9.33001 5.01302 9.46876 4.9203C9.60752 4.82759 9.71566 4.69581 9.77952 4.54164C9.84338 4.38746 9.86009 4.21781 9.82754 4.05414C9.79498 3.89047 9.71462 3.74013 9.59662 3.62213C9.47862 3.50413 9.32828 3.42377 9.16461 3.39121C9.00094 3.35866 8.83129 3.37537 8.67711 3.43923C8.52294 3.50309 8.39116 3.61123 8.29845 3.74999C8.20574 3.88874 8.15625 4.05187 8.15625 4.21875C8.15625 4.44253 8.24515 4.65714 8.40338 4.81537C8.56161 4.97361 8.77622 5.0625 9 5.0625ZM9 12.9375C8.83312 12.9375 8.66999 12.987 8.53124 13.0797C8.39248 13.1724 8.28434 13.3042 8.22048 13.4584C8.15662 13.6125 8.13991 13.7822 8.17246 13.9459C8.20502 14.1095 8.28538 14.2599 8.40338 14.3779C8.52138 14.4959 8.67172 14.5762 8.83539 14.6088C8.99906 14.6413 9.16871 14.6246 9.32289 14.5608C9.47706 14.4969 9.60884 14.3888 9.70155 14.25C9.79426 14.1113 9.84375 13.9481 9.84375 13.7813C9.84375 13.5575 9.75486 13.3429 9.59662 13.1846C9.43839 13.0264 9.22378 12.9375 9 12.9375Z" fill="black"/>
</svg>
            </div>
          <div className="font-bold text-[16px]">
           
          <h3 className="text-[#141414] flex items-start  text-center font-inter text-sm font-medium">Gross Income</h3>
          </div>
          <div className="flex">
            <h2 className="text-[#141414] font-inter text-lg font-bold">$179927</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="32" viewBox="0 0 38 32" fill="none">
  <path d="M27.3334 20V25.3333C27.3334 26.0697 26.7365 26.6667 26.0001 26.6667H20.6667C19.9304 26.6667 19.3334 26.0697 19.3334 25.3333C19.3334 24.597 19.9304 24 20.6667 24H22.8934L15.3334 15.2933L12.3334 18.32C12.0718 18.5842 11.7025 18.7123 11.3334 18.6667C10.9645 18.655 10.6169 18.4908 10.3734 18.2133L1.04008 7.54666C0.592346 6.99078 0.661798 6.18088 1.19764 5.70934C1.73348 5.2378 2.54563 5.27188 3.04008 5.78666L11.4401 15.4133L14.3867 12.4C14.6388 12.1307 14.9912 11.9779 15.3601 11.9779C15.7289 11.9779 16.0813 12.1307 16.3334 12.4L24.6667 21.9733V20C24.6667 19.2636 25.2637 18.6667 26.0001 18.6667C26.7365 18.6667 27.3334 19.2636 27.3334 20Z" fill="#141414"/>
</svg>



          </div>
        </div>
        

          </div>
          
       
      
    </>
  );
};
export default RetailerGrossIncomeDesktop;
