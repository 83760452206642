import React, { useEffect, useRef } from "react";

const WalkAndClickInfoModal = ({handleNext}) => {


    const timeoutRef = useRef(null);

    // Function to execute after the timeout (5 seconds)
    const executeAfterTimeout = () => {
        handleNext()
      // You can add the action you want to execute here
    };
  
    useEffect(() => {
      // Function to handle key press
      const handleKeyPress = (event) => {
        const key = event.key.toLowerCase();
  
        // Check if the pressed key is W, A, S, D, or Arrow keys
        if (["w", "a", "s", "d", "arrowup", "arrowdown", "arrowleft", "arrowright"].includes(key)) {
          // Clear the previous timeout if any key is pressed before 5 seconds
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
  
          // Set a new 5-second timeout
          timeoutRef.current = setTimeout(executeAfterTimeout, 5000);
        }
      };
  
      // Add event listener for keydown event
      window.addEventListener("keydown", handleKeyPress);
  
      // Cleanup the event listener and clear the timeout when the component is unmounted
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);



  const containerStyle = {
    position: "fixed", // Fixed position relative to the viewport
    bottom: "80px", // Position the component higher from the bottom of the screen
    left: "50%", // Start the component from the center horizontally
    transform: "translateX(-50%)", // Adjust to center horizontally
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent dark background
    borderRadius: "30px", // Rounded corners
    padding: "20px",
    width: "90%", // Set width to 90% for responsiveness
    maxWidth: "800px", // Max width for large screens
    margin: "0 auto", // Center the container horizontally
    color: "#fff", // White text
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  };

  const imageStyle = {
    width: "60px", // Set a fixed width for WASD image
    height: "auto",
    marginRight: "20px",
  };

  const cursorImageStyle = {
    width: "40px", // Make the cursor smaller
    height: "auto",
    marginRight: "20px",
  };

  const separatorStyle = {
    width: "1px",
    height: "60px",
    backgroundColor: "#ddd",
    margin: "0 20px",
  };

  const textStyle = {
    flex: 1, // Ensure text takes up remaining space
    lineHeight: "1.5",
  };

  // Add media queries for responsiveness
  const responsiveStyles = `
    @media (max-width: 600px) {
      #walkAndClickInfo {
        flex-direction: column; // Stack the content vertically on small screens
        text-align: center;
      }

      #walkAndClickInfo img {
        margin-bottom: 20px; // Add spacing between images and text when stacked
        margin-right: 0; // Remove right margin on smaller screens
      }

      #walkAndClickInfo .separator {
        display: none; // Hide the separator on small screens
      }
    }
  `;

  return (
    <>
      <style>{responsiveStyles}</style>
      <div style={containerStyle} id="walkAndClickInfo">
        {/* WASD Image */}
        <img src="/threejs/wasd.png" alt="WASD Keys" style={imageStyle} />

        {/* Vertical Separator */}
        <div className="separator" style={separatorStyle}></div>

        {/* Pointer Image */}
        <img src="/threejs/cursor.png" alt="cursor" style={cursorImageStyle} />

        {/* Instruction Text */}
        <div className="text" style={textStyle}>
          Try walking towards the placeholder by using the arrow keys or clicking on the floor where you want to move.
        </div>
      </div>
    </>
  );
};

export default WalkAndClickInfoModal;
