import { useState } from "react";

const StoreSelector = () => {
  const [stores] = useState(["Store 1", "Store 2", "Store 3"]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown state

  // Add store to selectedStores
  const handleSelectStore = (store) => {
    if (!selectedStores.includes(store)) {
      setSelectedStores([...selectedStores, store]);
    }
    setIsDropdownOpen(false); // Close dropdown after selecting a store
  };

  // Remove store from selectedStores
  const handleRemoveStore = (storeToRemove) => {
    setSelectedStores(
      selectedStores.filter((store) => store !== storeToRemove)
    );
  };

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex items-center space-x-4 font-inter">
      {/* Dropdown Button */}
      <div className="relative inline-block text-left">
        <button
          className="inline-flex justify-between items-center px-[28px] py-[10px] rounded-full bg-black text-sm text-white font-medium focus:outline-none"
          onClick={toggleDropdown} // Toggle dropdown on click
          id="menu-button"
        >
          Select Store
          <svg
            className="ml-2 -mr-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13.354 6.35403L8.35403 11.354C8.30759 11.4005 8.25245 11.4374 8.19175 11.4626C8.13105 11.4877 8.06599 11.5007 8.00028 11.5007C7.93457 11.5007 7.86951 11.4877 7.80881 11.4626C7.74811 11.4374 7.69296 11.4005 7.64653 11.354L2.64653 6.35403C2.55271 6.26021 2.5 6.13296 2.5 6.00028C2.5 5.8676 2.55271 5.74035 2.64653 5.64653C2.74035 5.55271 2.8676 5.5 3.00028 5.5C3.13296 5.5 3.26021 5.55271 3.35403 5.64653L8.00028 10.2934L12.6465 5.64653C12.693 5.60007 12.7481 5.56322 12.8088 5.53808C12.8695 5.51294 12.9346 5.5 13.0003 5.5C13.066 5.5 13.131 5.51294 13.1917 5.53808C13.2524 5.56322 13.3076 5.60007 13.354 5.64653C13.4005 5.69298 13.4373 5.74813 13.4625 5.80883C13.4876 5.86953 13.5006 5.93458 13.5006 6.00028C13.5006 6.06598 13.4876 6.13103 13.4625 6.19173C13.4373 6.25242 13.4005 6.30757 13.354 6.35403Z"
              fill="white"
            />
          </svg>
        </button>

        {/* Dropdown Menu */}
        {isDropdownOpen && ( // Conditionally render dropdown
          <div className="absolute z-50 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              {stores.map((store) => (
                <button
                  key={store}
                  onClick={() => handleSelectStore(store)}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                >
                  {store}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Selected Store Pills */}
      <div className="flex space-x-2">
        {selectedStores.map((store) => (
          <div
            key={store}
            className="flex items-center space-x-2 bg-[#DFC6FF] text-sm text-black px-[28px] py-[10px] font-medium rounded-full"
          >
            <span>{store}</span>
            <button onClick={() => handleRemoveStore(store)}>
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoreSelector;