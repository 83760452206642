import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import { Select } from "antd";
import "./style.css";

const RetailerFunnelChart = () => {
  const [selectedOption, setSelectedOption] = useState("All Users");

  const options = {
    legend: {
      data: ["User Entered", "Added to Cart", "Purchased"],
      textStyle: {
        fontSize: 5
      },
      itemWidth: 18,
      itemHeight: 10,
      top: 'auto',
      bottom: '90%',
      padding: [0, 0, 0, 0],
      y: 'top' 
    },
    series: [
      {
        name: "Expected",
        type: "funnel",
        left: "10%",
        width: "80%",
        label: {
          formatter: "{b} Expected",
          fontSize: 5,
          position: "right",
          distance: 5,
        },
        labelLine: {
          show: false,
        },
        itemStyle: {
          opacity: 0.7,
        },
        emphasis: {
          label: {
            position: "inside",
            formatter: "{b}Expected: {c}%",
            fontSize: 10,
          },
        },
        data: [
          { value: 60, name: "User Entered" },
          { value: 40, name: "Added to Cart" },
          { value: 20, name: "Purchased" },
        ],
      },
      {
        name: "Actual",
        type: "funnel",
        left: "10%",
        width: "80%",
        maxSize: "80%",
        label: {
          position: "inside",
          formatter: "{c}%",
          color: "#fff",
          fontSize: 5,
        },
        itemStyle: {
          opacity: 0.5,
          borderColor: "#fff",
          borderWidth: 2,
        },
        emphasis: {
          label: {
            position: "inside",
            formatter: "{b}Actual: {c}%",
          },
        },
        data: [
          { value: 90, name: "User Entered" },
          { value: 50, name: "Added to Cart" },
          { value: 20, name: "Purchased" },
        ],
        z: 100,
      },
    ],
  };

  return (
    <div className="relative circular-chart w-full h-full flex flex-col items-center justify-center bg-white pt-[20px] pb-[10px] overflow-hidden">
      <div className="w-full flex justify-between items-center mb-[20px] px-5">
        {/* The heading along with the info svg */}
        <div className="flex items-center">
          <span className="mr-2 font-bold">Funnel</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M8.58518 12.75H10.09V8.25H8.58518V12.75ZM9.33759 6.75C9.55078 6.75 9.7296 6.678 9.87406 6.534C10.0185 6.39 10.0905 6.212 10.09 6C10.0895 5.788 10.0173 5.61 9.87331 5.466C9.72935 5.322 9.55078 5.25 9.33759 5.25C9.12441 5.25 8.94584 5.322 8.80188 5.466C8.65792 5.61 8.58568 5.788 8.58518 6C8.58468 6.212 8.65691 6.39025 8.80188 6.53475C8.94684 6.67925 9.12541 6.751 9.33759 6.75ZM9.33759 16.5C8.29676 16.5 7.31862 16.303 6.40319 15.909C5.48775 15.515 4.69145 14.9807 4.01428 14.3062C3.33711 13.6317 2.80114 12.838 2.40638 11.925C2.01161 11.012 1.81398 10.037 1.81348 9C1.81298 7.963 2.01061 6.988 2.40638 6.075C2.80215 5.162 3.33811 4.36825 4.01428 3.69375C4.69045 3.01925 5.48675 2.485 6.40319 2.091C7.31963 1.697 8.29776 1.5 9.33759 1.5C10.3774 1.5 11.3556 1.697 12.272 2.091C13.1884 2.485 13.9847 3.01925 14.6609 3.69375C15.3371 4.36825 15.8733 5.162 16.2696 6.075C16.6658 6.988 16.8632 7.963 16.8617 9C16.8602 10.037 16.6626 11.012 16.2688 11.925C15.875 12.838 15.3391 13.6317 14.6609 14.3062C13.9827 14.9807 13.1864 15.5152 12.272 15.9097C11.3576 16.3042 10.3794 16.501 9.33759 16.5ZM9.33759 15C11.018 15 12.4413 14.4187 13.6075 13.2562C14.7738 12.0937 15.3569 10.675 15.3569 9C15.3569 7.325 14.7738 5.90625 13.6075 4.74375C12.4413 3.58125 11.018 3 9.33759 3C7.65721 3 6.2339 3.58125 5.06766 4.74375C3.90142 5.90625 3.3183 7.325 3.3183 9C3.3183 10.675 3.90142 12.0937 5.06766 13.2562C6.2339 14.4187 7.65721 15 9.33759 15Z"
              fill="#A6A6A6"
            />
          </svg>
        </div>
        {/* The Dropdown */}
        <div>
          <Select
            value={selectedOption}
            onChange={(value) => setSelectedOption(value)}
            style={{ width: 120 }}
          >
            <Select.Option value="All Users">Weekly</Select.Option>
            <Select.Option value="Active Users">Monthly</Select.Option>
            <Select.Option value="Inactive Users">Yearly</Select.Option>
          </Select>
        </div>
      </div>

      {/* The chart */}
      <div className="chart-container flex justify-center items-center">
        <ReactECharts
          option={options}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
};

export default RetailerFunnelChart;
