import React from "react";

const RetailerFeedbackMeter = () => {
  const progress = 25;

  return (
    <div className="relative circular-chart w-full h-full flex flex-col items-center justify-center bg-white pt-[20px] pb-[15px]">
      {/* The heading along with the info svg */}
      <div className="w-full flex flex-col justify-center items-start mb-[15px] px-5">
        <div className="flex items-center gap-2">
          <span className="font-bold">Feedback Meter</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M8.58518 12.75H10.09V8.25H8.58518V12.75ZM9.33759 6.75C9.55078 6.75 9.7296 6.678 9.87406 6.534C10.0185 6.39 10.0905 6.212 10.09 6C10.0895 5.788 10.0173 5.61 9.87331 5.466C9.72935 5.322 9.55078 5.25 9.33759 5.25C9.12441 5.25 8.94584 5.322 8.80188 5.466C8.65792 5.61 8.58568 5.788 8.58518 6C8.58468 6.212 8.65691 6.39025 8.80188 6.53475C8.94684 6.67925 9.12541 6.751 9.33759 6.75ZM9.33759 16.5C8.29676 16.5 7.31862 16.303 6.40319 15.909C5.48775 15.515 4.69145 14.9807 4.01428 14.3062C3.33711 13.6317 2.80114 12.838 2.40638 11.925C2.01161 11.012 1.81398 10.037 1.81348 9C1.81298 7.963 2.01061 6.988 2.40638 6.075C2.80215 5.162 3.33811 4.36825 4.01428 3.69375C4.69045 3.01925 5.48675 2.485 6.40319 2.091C7.31963 1.697 8.29776 1.5 9.33759 1.5C10.3774 1.5 11.3556 1.697 12.272 2.091C13.1884 2.485 13.9847 3.01925 14.6609 3.69375C15.3371 4.36825 15.8733 5.162 16.2696 6.075C16.6658 6.988 16.8632 7.963 16.8617 9C16.8602 10.037 16.6626 11.012 16.2688 11.925C15.875 12.838 15.3391 13.6317 14.6609 14.3062C13.9827 14.9807 13.1864 15.5152 12.272 15.9097C11.3576 16.3042 10.3794 16.501 9.33759 16.5ZM9.33759 15C11.018 15 12.4413 14.4187 13.6075 13.2562C14.7738 12.0937 15.3569 10.675 15.3569 9C15.3569 7.325 14.7738 5.90625 13.6075 4.74375C12.4413 3.58125 11.018 3 9.33759 3C7.65721 3 6.2339 3.58125 5.06766 4.74375C3.90142 5.90625 3.3183 7.325 3.3183 9C3.3183 10.675 3.90142 12.0937 5.06766 13.2562C6.2339 14.4187 7.65721 15 9.33759 15Z"
              fill="#A6A6A6"
            />
          </svg>
        </div>

        {/* The feedback meter */}
        <div className="w-full flex justify-center items-center relative h-24 mt-4">
          <svg className="w-full h-full" viewBox="0 0 100 50">
            {/* Background arc */}
            <path
              d="M5 50a45 45 0 0190 0"
              fill="none"
              stroke="#e6e6e6"
              strokeWidth="5"
              strokeLinecap="round"
            />
            {/* Progress arc */}
            <path
              d="M5 50a45 45 0 0190 0"
              fill="none"
              stroke="#9747FF"
              strokeWidth="5"
              strokeDasharray="141"
              strokeDashoffset={141 - (progress * 1.41)}
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-[#ECEAF8] rounded-full p-[11px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
          >
            <path
              d="M17.0002 30.126C24.5503 30.126 30.6928 23.9836 30.6928 16.4336C30.6928 8.88362 24.5503 2.74121 17.0002 2.74121C9.45011 2.74121 3.30762 8.88362 3.30762 16.4336C3.30762 23.9836 9.45011 30.126 17.0002 30.126ZM21.783 12.3259C22.056 12.3189 22.3277 12.3667 22.5819 12.4663C22.8362 12.566 23.0679 12.7156 23.2634 12.9063C23.459 13.0969 23.6143 13.3248 23.7204 13.5764C23.8264 13.8281 23.881 14.0984 23.8809 14.3715C23.8808 14.6446 23.8261 14.9149 23.7198 15.1665C23.6136 15.4181 23.4581 15.6459 23.2624 15.8364C23.0668 16.0269 22.835 16.1764 22.5807 16.2759C22.3263 16.3754 22.0547 16.423 21.7817 16.4158C21.2485 16.4018 20.7418 16.1801 20.3697 15.798C19.9976 15.4159 19.7894 14.9035 19.7896 14.3702C19.7898 13.8368 19.9983 13.3246 20.3706 12.9427C20.743 12.5608 21.2498 12.3395 21.783 12.3259ZM15.8938 21.7996C16.6241 21.9461 17.3763 21.9461 18.1066 21.7996C18.453 21.7284 18.798 21.6203 19.1335 21.4806C19.4539 21.3437 19.7688 21.1739 20.0632 20.9767C20.348 20.7823 20.6205 20.5577 20.8725 20.3072C21.123 20.058 21.3476 19.7855 21.542 19.4966L23.8123 21.026C23.222 21.9008 22.4695 22.6543 21.5954 23.2456C20.7049 23.8465 19.7052 24.267 18.6529 24.4834C17.562 24.7025 16.4383 24.702 15.3475 24.482C14.2949 24.2688 13.2951 23.8485 12.4063 23.2456C11.5323 22.6528 10.779 21.8991 10.1868 21.0247L12.457 19.4952C12.6528 19.7841 12.8774 20.0566 13.1252 20.3031C13.8812 21.0628 14.8441 21.5833 15.8938 21.7996ZM12.2078 12.3259C12.4776 12.326 12.7448 12.3792 12.994 12.4825C13.2432 12.5859 13.4697 12.7373 13.6604 12.9281C13.8511 13.119 14.0024 13.3455 14.1056 13.5948C14.2087 13.8441 14.2618 14.1113 14.2617 14.3811C14.2616 14.6509 14.2084 14.9181 14.105 15.1673C14.0017 15.4165 13.8503 15.643 13.6594 15.8337C13.4686 16.0244 13.242 16.1757 12.9927 16.2788C12.7434 16.382 12.4762 16.4351 12.2064 16.435C11.6615 16.4348 11.139 16.2182 10.7538 15.8327C10.3686 15.4473 10.1524 14.9246 10.1525 14.3797C10.1527 13.8348 10.3694 13.3123 10.7548 12.9272C11.1402 12.542 11.6629 12.3257 12.2078 12.3259Z"
              fill="#9747FF"
            />
          </svg>
        </div>

        {/* The feedback meter text */}
        <div className="w-full flex justify-center items-center">
          <h1 className="text-[20px] font-bold mt-[50px]">Need Improvement</h1>
        </div>
      </div>
    </div>
  );
};

export default RetailerFeedbackMeter;
