import React, { useEffect, useState } from "react";
import usePublishStoreRole from "../../../hook/usePublishStoreRole";
import avatarModelApi from "../../../api/avatarModel.api";
import { notification } from "antd";
import _ from "lodash";
import { getAssetsUrl } from "../../../utils/util";

const SelectAvatarCharector = ({onStart,selectedAvatarModelId,setSelectedAvatarModelId}) => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [listAvatarModels, setListAvatarModels] = useState([])
  const { publishRole, isLoading: isLoadingRole } = usePublishStoreRole()
  

 useEffect(() => {
        if(!isLoadingRole) {
            avatarModelApi.getAllAvatarModels({roleFor: publishRole}).then(rs => {
                setListAvatarModels(rs)
            }).catch(err => {
                notification.error({
                    message: _.get(err, ['response', 'data', 'message'], `Can't get list avatars!`)
                })
            })
        }
    }, [publishRole, isLoadingRole])

  const modalStyle = {
    position: "fixed", // Fixed positioning to center the modal on the screen
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Centers the modal both horizontally and vertically
    width: "400px", // Reduced width for the modal
    maxWidth: "90vw", // Ensure responsiveness for smaller screens
    padding: "20px",
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark translucent background
    borderRadius: "15px", // Rounded corners
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    color: "#fff",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Light shadow for depth
  };

  const titleStyle = {
    fontSize: "20px", // Slightly smaller title size
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: "14px", // Slightly smaller description text
    marginBottom: "15px",
    color: "#ddd", // Light gray for the description text
  };

  const avatarGridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))", // Auto adjusts columns
    gap: "10px", // Space between avatars
    width: "100%", // Make grid take up full width on smaller screens
  };
  
  const avatarGridContainerStyle = {
    display: "flex",
    justifyContent: "center", // Center grid horizontally
    padding: "20px",
  };

  

  const avatarStyle = {
    width: "50px", // Further reduced avatar size
    height: "50px",
    borderRadius: "50%", // Circular avatar
    cursor: "pointer", // Make avatars clickable
    border: selectedAvatar === null ? "2px solid transparent" : "2px solid #16F6FE", // Highlight selected avatar
  };

  const avatarImageStyle = {
    width: "100%", // Full width of the avatar
    height: "100%", // Full height of the avatar
    borderRadius: "50%", // Circular avatar image
    objectFit: "cover", // Cover the container
  };

  const avatars = [
    "https://via.placeholder.com/50", // Dummy image 1
    "https://via.placeholder.com/50", // Dummy image 2
    "https://via.placeholder.com/50", // Dummy image 3
    "https://via.placeholder.com/50", // Dummy image 4
    "https://via.placeholder.com/50", // Dummy image 5
    "https://via.placeholder.com/50", // Dummy image 6
  ];

  const handleAvatarClick = (index) => {
    setSelectedAvatar(index); // Set the clicked avatar as selected
  };

  const buttonStyle = {
    padding: "10px 20px", // Adjusted button size
    borderRadius: "10px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    background: "linear-gradient(to right, #16F6FE, #AB00FF)", // Gradient for the "Select" button
    color: "#fff",
    border: "none",
  };

  return (
    <div style={modalStyle} id="selectAvatarModal">
      <div style={titleStyle}>Select avatar</div>
      <div style={descriptionStyle}>
        Now let's check out what your customer will see.
      </div>

      {/* Avatar Grid Container */}
      <div style={avatarGridContainerStyle}>
      {/* Avatar Grid */}
      <div style={avatarGridStyle}>
        {/* Map over avatars array to display them */}
        {
                                                    listAvatarModels &&
                                                    listAvatarModels.map((el) => (
                                                        <>
                                                            <div 
                                                                key={el.id}
style={{ width: "100%", height: "100px" }}
                                                                className={`rounded-full ${selectedAvatarModelId === el.id ? 'border border-white' : ''}`} 
                                                                onClick={() => {setSelectedAvatarModelId(el.id)}}
                                                            >
                                                              {console.log(el,'THIS IS ELEMENET',getAssetsUrl(el?.thumnail),'THIS IS DATATAA')
                                                              }
                                                                <img 
                                                                className="w-full h-full rounded-full object-cover "
                                                                    src={getAssetsUrl(el?.thumnail)}
                                                                    alt="" 
                                                                />
                                                            </div>
                                                        </>
                                                    ))
                                                }
       
       
        
      </div>
    </div>

      <button style={buttonStyle} onClick={onStart}>Select</button>
    </div>
  );
};

export default SelectAvatarCharector;
