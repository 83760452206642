import React from "react";

const WalkAndNavigationModal = ({handleNext}) => {
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "30px",
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark translucent background
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    borderRadius: "20px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    color: "#fff",
  };

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  };

  const instructionStyle = {
    fontSize: "14px",
    marginBottom: "20px",
    color: "#ddd",
  };

  const buttonStyle = {
    padding: "12px 40px",
    borderRadius: "10px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    background: "linear-gradient(to right, #16F6FE, #AB00FF)", // Gradient for the button
    color: "#fff",
    border: "none",
  };

  const imageStyle = {
    display: "block", // This will make the image behave like a block element
    margin: "0 auto", // This will center the image horizontally
    width: "200px", // Set the width of the image
    marginBottom: "20px",
  };

  // Add media queries for responsiveness
  const responsiveStyles = `
    @media (max-width: 600px) {
      #walkAndNavigateModal {
        width: 90%;
        padding: 20px;
      }

      #walkAndNavigateModal .title {
        font-size: 20px;
      }

      #walkAndNavigateModal .instruction {
        font-size: 12px;
      }

      #walkAndNavigateModal button {
        padding: 10px 30px;
        font-size: 14px;
      }

      #walkAndNavigateModal img {
        width: 150px; /* Reduce image size on small screens */
      }
    }
  `;

  return (
    <>
      <style>{responsiveStyles}</style>
      <div style={modalStyle} id="walkAndNavigateModal">
        <div className="title" style={titleStyle}>
          Walk & Navigate
        </div>

        {/* WASD Image Section */}
        <img src="/threejs/wasd.png" alt="WASD Keys" style={imageStyle} />

        {/* Instruction text */}
        <div className="instruction" style={instructionStyle}>
          Use the arrow keys or W, A, S, D to walk around your virtual store.
        </div>

        {/* Button */}
        <button style={buttonStyle} onClick={handleNext}>Try walking</button>
      </div>
    </>
  );
};

export default WalkAndNavigationModal;
